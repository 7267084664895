import { z } from 'zod';
import { UserPublicDataSchema } from './userPublicData';

export const UpdateUserInputSchema = UserPublicDataSchema.partial().omit({
    id: true,
    email: true,
    utc_created: true,
    utc_last_login: true
});

export type UpdateUserInput = z.infer<typeof UpdateUserInputSchema>;
