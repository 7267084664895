import styled from '@emotion/styled';
import { Box, CircularProgress, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import { ApiErrorResponseSchema, Application, LongTextApplicationQuestion } from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import * as React from 'react';
import { debounce } from 'ts-debounce';
import { ApiContext } from '../../../providers/ApiProvider';
import Subtext from './subtext';

const StyledPre = styled('pre')(() => ({
    whiteSpace: 'pre-wrap',
    marginTop: 'initial',
    marginBottom: 'initial'
}));

export type LongTextResponseProps = LongTextApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const LongTextResponse: React.FC<LongTextResponseProps> = (props: LongTextResponseProps) => {
    const {
        prompt,
        id,
        responses,
        minLength,
        maxLength,
        editable,
        applicationId,
        subtext,
        validate,
        onResponseChanged
    } = props;
    const [response, setResponse] = React.useState(responses[id] || '');
    const [savedResponse, setSavedResponse] = React.useState(responses[id] || '');
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);

    React.useEffect(() => {
        if (validate && (!response || response.trim().length < 1)) {
            setError('please enter a response.');
        }
    }, [validate, response]);

    const handleTextValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value }
        } = event;

        saveToApiDebounced(value).catch(() => {
            //empty block
        });

        setResponse(value);

        if (onResponseChanged) {
            onResponseChanged(value);
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);

                setSavedResponse(value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(`save failed: ${response.data.message}`);
                    } else {
                        setError('save failed: an unknown error occurred');
                    }
                } else {
                    setError('save failed: an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    const saveToApiDebounced = React.useMemo(() => debounce(saveToApi, 5000), []);

    const handleBlur = () => {
        saveToApiDebounced.cancel();

        if (response !== savedResponse) {
            saveToApi(response);
        }
    };

    return (
        <>
            <p>
                {prompt}
                {subtext && (
                    <>
                        <br />
                        <Subtext>{subtext}</Subtext>
                    </>
                )}
            </p>
            {editable && (
                <FormControl fullWidth error={Boolean(error)}>
                    <TextField
                        error={Boolean(error)}
                        id={`textfield-${id}`}
                        label="Response"
                        multiline
                        minRows={4}
                        maxRows={16}
                        fullWidth
                        value={response}
                        disabled={!editable}
                        onChange={handleTextValueChange}
                        onBlur={handleBlur}
                        inputProps={{
                            minLength: minLength,
                            maxLength: maxLength
                        }}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                    <Box sx={{ display: 'flex', height: '26px' }}>
                        <Box sx={{ flexBasis: '50%', fontSize: '0.8em', marginTop: '4px', marginLeft: '4px' }}>
                            {saving && (
                                <>
                                    <CircularProgress size={16} />
                                    &nbsp;saving...
                                </>
                            )}
                        </Box>
                        <Box sx={{ width: '100%', textAlign: 'right', fontSize: '0.8em', flexBasis: '50%' }}>
                            {/* [ {response.length} / {maxLength} ]*/}
                        </Box>
                    </Box>
                </FormControl>
            )}
            {!editable && (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexShrink: 0, flexGrow: 0 }}>
                        <Typography variant="body1" component="span" sx={{ marginLeft: '24px', fontWeight: 'bold' }}>
                            Response:&nbsp;&nbsp;
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <StyledPre>{response || 'No response provided.'}</StyledPre>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default LongTextResponse;
