import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const CreateAuthResetSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const CreateAuthResetResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    CreateAuthResetSuccessResponseSchema
]);
export type CreateAuthResetResponse = z.infer<typeof CreateAuthResetResponseSchema>;
