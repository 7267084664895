import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const LongTextApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('longText'),
        prompt: z.string(),
        subtext: z.string().optional(),
        minLength: z.number(),
        maxLength: z.number()
    })
);
export type LongTextApplicationQuestion = z.infer<typeof LongTextApplicationQuestionSchema>;
