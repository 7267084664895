import { z } from 'zod';
import { CheckboxApplicationQuestionSchema } from './checkboxApplicationQuestion';
import { DropdownApplicationQuestionSchema } from './dropdownApplicationQuestion';
import { LegalApplicationQuestionSchema } from './legalApplicationQuestion';
import { LongTextApplicationQuestionSchema } from './longTextApplicationQuestion';
import { MoneyApplicationQuestionSchema } from './moneyApplicationQuestion';
import { MultipleChoiceApplicationQuestionSchema } from './multipleChoiceApplicationQuestion';
import { MultipleSelectApplicationQuestionSchema } from './multipleSelectApplicationQuestion';
import { NumberApplicationQuestionSchema } from './numberApplicationQuestion';
import { SectionApplicationQuestionSchema } from './sectionApplicationQuestion';
import { ShortTextApplicationQuestionSchema } from './shortTextApplicationQuestion';

export const ApplicationQuestionSchema = z.discriminatedUnion('type', [
    DropdownApplicationQuestionSchema,
    MoneyApplicationQuestionSchema,
    MultipleChoiceApplicationQuestionSchema,
    MultipleSelectApplicationQuestionSchema,
    NumberApplicationQuestionSchema,
    SectionApplicationQuestionSchema,
    ShortTextApplicationQuestionSchema,
    CheckboxApplicationQuestionSchema,
    LongTextApplicationQuestionSchema,
    LegalApplicationQuestionSchema
]);
export type ApplicationQuestion = z.infer<typeof ApplicationQuestionSchema>;
