import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const AdvisorUpdateUserPasswordSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const AdvisorUpdateUserPasswordResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    AdvisorUpdateUserPasswordSuccessResponseSchema
]);
export type AdvisorUpdateUserPasswordResponse = z.infer<typeof AdvisorUpdateUserPasswordResponseSchema>;
