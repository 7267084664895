import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const CreateRegistrationSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const CreateRegistrationResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    CreateRegistrationSuccessResponseSchema
]);
export type CreateRegistrationResponse = z.infer<typeof CreateRegistrationResponseSchema>;
