import { Divider } from '@mui/material';
import * as React from 'react';
import canonicalizeHtmlId from '../../../util/canonicalizeHtmlId';
import { SectionApplicationQuestion } from '@rqr/deal-flow-abstractions';

export type SectionResponseProps = SectionApplicationQuestion & { editable: boolean; validate?: boolean };

const SectionResponse: React.FC<SectionResponseProps> = (props: SectionResponseProps) => {
    const { name } = props;

    return (
        <>
            <br />
            <br />
            <a id={`${canonicalizeHtmlId(name)}`}></a>
            <Divider textAlign="left">{name}</Divider>
        </>
    );
};

export default SectionResponse;
