import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const MoneyApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('money'),
        prompt: z.string(),
        subtext: z.string().optional(),
        minValue: z.number(),
        maxValue: z.number(),
        decimals: z.number()
    })
);
export type MoneyApplicationQuestion = z.infer<typeof MoneyApplicationQuestionSchema>;
