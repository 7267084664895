import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    IconButton,
    InputAdornment,
    LinearProgress,
    LinearProgressProps,
    TextField,
    TextFieldProps
} from '@mui/material';
import React, { useEffect } from 'react';
import { passwordStrength } from 'check-password-strength';

export type PasswordFieldProps = {
    buttonDisabled: boolean;
    visible: boolean;
} & TextFieldProps;

const colors: LinearProgressProps['color'][] = ['error', 'warning', 'info', 'success'];

function removeOffensiveProps(props: PasswordFieldProps): Omit<PasswordFieldProps, 'visible' | 'buttonDisabled'> {
    const result: Partial<PasswordFieldProps> = {
        ...props
    };

    delete result.visible;
    delete result.buttonDisabled;

    return result;
}

const PasswordField: React.FC<PasswordFieldProps> = (props: PasswordFieldProps) => {
    const [visible, setVisible] = React.useState(props.visible);
    const [strength, setStrength] = React.useState(0);
    const [color, setColor] = React.useState<LinearProgressProps['color']>('error');
    const [value, setValue] = React.useState(props.value || '');

    useEffect(() => {
        setColor(colors[strength]);
    }, [strength]);

    const normalize = (min: number, max: number) => (value: number) => ((value - min) * 100) / (max - min);
    const normalizeStrength = normalize(0, 3);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setStrength(passwordStrength(event.target.value).id);

        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <>
            <TextField
                {...removeOffensiveProps(props)}
                type={visible ? 'text' : 'password'}
                onChange={handleChange}
                value={value}
                InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setVisible(!visible)}
                                onMouseDown={(e) => e.preventDefault()}
                                disabled={props.buttonDisabled}
                            >
                                {visible && <Visibility />}
                                {!visible && <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <LinearProgress
                variant="determinate"
                value={normalizeStrength(strength)}
                color={color}
                sx={{ marginTop: '2px', marginLeft: '2px', marginRight: '2px' }}
            />
        </>
    );
};

export default PasswordField;
