import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ApplicationsList from './applicationsList';
import NewApplicationForm from './newApplicationForm';
import ViewApplication from './viewApplication';

const Applications = () => {
    return (
        <Routes>
            <Route path="/*" element={<ApplicationsList />} />
            <Route path="new" element={<NewApplicationForm />} />
            <Route path="view/:applicationId" element={<ViewApplication />} />
        </Routes>
    );
};

export default Applications;
