import { Avatar, Box, Button, CircularProgress, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../providers/ApiProvider';
import styled from '@emotion/styled';
import { LockOutlined, ReportProblem } from '@mui/icons-material';
import Copyright from '../../components/Copyright';

const StyledReportProblem = styled(ReportProblem)(() => ({
    color: 'red'
}));

const ResetRequest = () => {
    const { id } = useParams();
    const api = React.useContext(ApiContext);
    const [error, setError] = React.useState<Error>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isRequestComplete, setIsRequestComplete] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        (async (code?: string) => {
            if (!code) {
                //should be impossible
                setError(new Error('no reset id was provided'));
            }

            setIsLoading(false);
        })(id);
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!id) {
            //should be impossible
            setError(new Error('no reset id was provided'));
            return;
        }

        if (!event.currentTarget.reportValidity()) {
            return;
        }

        const theId = id; //immortalize
        const data = new FormData(event.currentTarget);
        setIsSubmitting(true);

        (async () => {
            await api.user.createResetRequest(theId, data.get('email')?.toString() || '');

            setIsRequestComplete(true);
        })();
    };

    if (isLoading) {
        return (
            <Stack alignItems="center" m={33}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <>
            {error && (
                <>
                    <Box sx={{ width: '100%', marginTop: '64px' }}>
                        <Box sx={{ position: 'relative', width: '50%', left: '33%' }}>
                            <StyledReportProblem fontSize="large" sx={{ position: 'absolute', top: '6px' }} />
                            &nbsp;&nbsp;
                            <Typography component="span" sx={{ position: 'absolute', marginLeft: '48px' }}>
                                An error occurred loading the application:
                            </Typography>
                        </Box>
                        <Box sx={{ position: 'relative', width: '50%', left: '33%' }}>
                            <Typography component="span" sx={{ position: 'absolute', marginLeft: '48px' }}>
                                &nbsp;&nbsp;{error.message}
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}
            {!error && (
                <>
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlined />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Password Reset
                            </Typography>
                            {isRequestComplete && (
                                <>
                                    <br />
                                    <Typography>Please check your email for a reset link.</Typography>
                                </>
                            )}
                            {!isRequestComplete && (
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                type="email"
                                                value={email}
                                                disabled={isSubmitting}
                                                onChange={(event) => setEmail(event.target.value)}
                                                autoComplete="email"
                                            />
                                        </Grid>
                                    </Grid>
                                    {isSubmitting && (
                                        <Stack alignItems="center" sx={{ marginTop: '24px' }}>
                                            <CircularProgress />
                                        </Stack>
                                    )}
                                    {!isSubmitting && (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={!email}
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Request Reset
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                        <br />
                        <Copyright />
                    </Container>
                </>
            )}
        </>
    );
};

export default ResetRequest;
