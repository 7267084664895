import styled from '@emotion/styled';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiContext } from '../../providers/ApiProvider';
import Breakpoints from '../../util/breakpoints';
import CursorLink from '../CursorLink';
import LongTextResponse from './components/longTextResponse';
import canonicalizeHtmlId from '../../util/canonicalizeHtmlId';
import SectionResponse from './components/sectionResponse';
import MultipleChoiceResponse from './components/multipleChoiceResponse';
import MultipleSelectResponse from './components/multipleSelectResponse';
import CheckboxResponse from './components/checkboxResponse';
import { AuthContext } from '../../providers/AuthProvider';
import { Print, ReportProblem } from '@mui/icons-material';
import {
    Application,
    ApplicationQuestion,
    ApplicationStatus,
    SectionApplicationQuestion,
    UserRole,
    ValidateApplicationQuestionResponse
} from '@rqr/deal-flow-abstractions';
import DropdownResponse from './components/dropdownResponse';
import ShortTextResponse from './components/shortTextResponse';
import LegalResponse from './components/legalResponse';
import { useReactToPrint } from 'react-to-print';

const HUNDRED_MINUS_332 = 'calc(100% - 332px)';
const INLINE_BLOCK = 'inline-block';

const FloatingNav = styled(Paper)(() =>
    Breakpoints({
        position: 'fixed',
        left: [
            HUNDRED_MINUS_332,
            HUNDRED_MINUS_332,
            HUNDRED_MINUS_332,
            HUNDRED_MINUS_332,
            HUNDRED_MINUS_332,
            'calc(100% / 2 + 316px)'
        ],
        top: '68.5px',
        width: '300px',
        marginRight: '32px',
        marginTop: '20px',
        display: ['none', 'none', 'none', 'block']
    })
);

const PrintableComponent = React.forwardRef(
    (props: { children: JSX.Element | (JSX.Element | undefined)[] | undefined }, ref) => {
        let children: (JSX.Element | undefined)[] = [];

        if (props.children) {
            if (Array.isArray(props.children)) {
                children = props.children;
            } else {
                children = [props.children];
            }
        }

        return <Box ref={ref} children={children} />;
    }
);

const StyledBox = styled(Box)(() =>
    Breakpoints({
        width: ['100%', '100%', '100%', 'calc(100% - 282px)']
    })
);

const StyledReportProblem = styled(ReportProblem)(() => ({
    color: 'red'
}));

export interface ApplicationEditorProps {
    breadcrumbs?: (application: Application) => JSX.Element;
}

type InvalidQuestionResponse = {
    question: ApplicationQuestion;
    response: string;
};

function getInvalidQuestionResponses(
    questions: ApplicationQuestion[],
    responses: Record<string, string>
): InvalidQuestionResponse[] {
    return questions
        .map((question) => {
            if (question.type === 'section') {
                return false;
            }

            const response = responses[question.id];

            if (!response || response.length < 1) {
                return {
                    question,
                    response
                };
            }

            const parseResult = ValidateApplicationQuestionResponse(question, response);

            if (!parseResult.success) {
                return {
                    question,
                    response
                };
            }

            return false;
        })
        .reduce<InvalidQuestionResponse[]>((memo, item) => {
            if (typeof item !== 'boolean') {
                return [...memo, item];
            }

            return memo;
        }, []);
}

const ApplicationEditor: React.FC<ApplicationEditorProps> = (props: ApplicationEditorProps) => {
    const auth = React.useContext(AuthContext);
    const [application, setApplication] = React.useState<Application>();
    const [responses, setResponses] = React.useState<Record<string, string>>({});
    const [editable, setEditable] = React.useState(false);
    const [error, setError] = React.useState<Error>();
    const [isInValidationMode, setIsInValidationMode] = React.useState(false);
    const { applicationId } = useParams();
    const [open, setOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const api = React.useContext(ApiContext);
    const navigate = useNavigate();
    const printableRef = React.useRef();

    const handlePrint = useReactToPrint({
        documentTitle: `RQR Application - ${application?.title}`,
        pageStyle: 'body { margin: 8px } @media print{@page {size: portrait}}',
        content: () => printableRef.current || null
    });

    React.useEffect(() => {
        if (!applicationId) {
            return;
        }

        (async () => {
            try {
                const result = await api.applications.getApplication(applicationId);

                if (result.status !== 'OK') {
                    throw new Error('impossible condition');
                }

                result.application.questions.sort((a, b) => a.order - b.order);

                setEditable(
                    auth.user?.role === UserRole.Applicant && result.application.status === ApplicationStatus.Draft
                );
                setApplication(result.application);
                setResponses(result.application.responses);
            } catch (e) {
                if (e instanceof Error) {
                    setError(e);
                } else if (typeof e === 'string') {
                    setError(new Error(e));
                } else {
                    setError(new Error('an unkown error has occured'));
                }
            }
        })();
    }, []);

    const handleSubmit = () => {
        setOpen(true);
    };

    const handleResponseChanged = (questionId: string) => (newValue: string) => {
        responses[questionId] = newValue;

        setResponses(responses);
    };

    const handleClose = (cancelled: boolean) => () => {
        if (cancelled) {
            setOpen(false);

            return;
        }

        setIsSubmitting(true);
        setIsInValidationMode(true);

        const invalidQuestionResponses = getInvalidQuestionResponses(application?.questions || [], responses);
        const responsesAreValid = invalidQuestionResponses.length === 0;

        if (!responsesAreValid) {
            //oh
            setIsSubmitting(false);
            setOpen(false);

            setTimeout(() => (window.location.hash = `question-${invalidQuestionResponses[0].question.id}`), 0);

            return;
        }

        (async () => {
            if (!applicationId) {
                throw new Error('impossible condition');
            }

            try {
                await api.applications.updateApplication(applicationId, {
                    status: ApplicationStatus.InReview
                });
            } catch (e) {
                //todo: handle unhappy path
            }

            navigate('..');
        })();
    };

    return (
        <>
            {error && (
                <>
                    <Box sx={{ width: '100%', marginTop: '64px' }}>
                        <Box sx={{ position: 'relative', width: '50%', left: '33%' }}>
                            <StyledReportProblem fontSize="large" sx={{ position: 'absolute', top: '6px' }} />
                            &nbsp;&nbsp;
                            <Typography component="span" sx={{ position: 'absolute', marginLeft: '48px' }}>
                                An error occurred loading the application:
                            </Typography>
                        </Box>
                        <Box sx={{ position: 'relative', width: '50%', left: '33%' }}>
                            <Typography component="span" sx={{ position: 'absolute', marginLeft: '48px' }}>
                                &nbsp;&nbsp;{error.message}
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}
            {applicationId && !application && !error && (
                <>
                    <FloatingNav>
                        <List>
                            {Array.from(Array(8).keys()).map((i) => (
                                <ListItem key={`skeleton-nav-link-${i}`}>
                                    <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                </ListItem>
                            ))}
                        </List>
                    </FloatingNav>
                    <StyledBox>
                        <Skeleton variant="rectangular" animation="wave" />
                        <br />
                        <Skeleton variant="rectangular" animation="wave" />
                        <br />
                        <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%', height: '125px' }} />
                        <br />
                        <Skeleton variant="rectangular" animation="wave" />
                        <br />
                        <Skeleton
                            variant="circular"
                            animation="wave"
                            sx={{ width: '20px', height: '20px', display: INLINE_BLOCK }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Skeleton
                            variant="circular"
                            animation="wave"
                            sx={{ width: '20px', height: '20px', display: INLINE_BLOCK }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Skeleton
                            variant="circular"
                            animation="wave"
                            sx={{ width: '20px', height: '20px', display: INLINE_BLOCK }}
                        />
                    </StyledBox>
                </>
            )}
            {applicationId && application && !error && (
                <>
                    <FloatingNav key="floating-nav">
                        <List>
                            {application.questions
                                .filter(
                                    (question): question is SectionApplicationQuestion => question.type === 'section'
                                )
                                .map((section: SectionApplicationQuestion) => {
                                    return (
                                        <ListItem key={`nav-link-${section.id}`}>
                                            <CursorLink href={`#${canonicalizeHtmlId(section.name)}`}>
                                                {section.name}
                                            </CursorLink>
                                        </ListItem>
                                    );
                                })}
                            {editable && (
                                <>
                                    <ListItem key="nav-link-submit">
                                        <CursorLink href="#submit">Submit</CursorLink>
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </FloatingNav>
                    <StyledBox key="styled-box">
                        {props.breadcrumbs && (
                            <div key="breadcrumbs-div" style={{ display: 'inline-block' }}>
                                {props.breadcrumbs(application)}
                            </div>
                        )}
                        <Print
                            key="print-icon"
                            onClick={handlePrint}
                            sx={{ display: 'inline-block', float: 'right', cursor: 'pointer' }}
                        />
                        <PrintableComponent key="printable" ref={printableRef}>
                            <>
                                {(() => {
                                    let cachedComponents: JSX.Element[] = [];

                                    return application.questions.map((question) => {
                                        let isSection = false;

                                        const questionElement = (() => {
                                            switch (question.type) {
                                                case 'section':
                                                    isSection = true;
                                                    return <SectionResponse key={question.id} {...question} editable />;
                                                case 'longText':
                                                    return (
                                                        <LongTextResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'shortText':
                                                    return (
                                                        <ShortTextResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'multipleChoice':
                                                    return (
                                                        <MultipleChoiceResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'multipleSelect':
                                                    return (
                                                        <MultipleSelectResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'checkbox':
                                                    return (
                                                        <CheckboxResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'dropdown':
                                                    return (
                                                        <DropdownResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                case 'legal':
                                                    return (
                                                        <LegalResponse
                                                            key={question.id}
                                                            applicationId={application.id}
                                                            {...question}
                                                            responses={responses}
                                                            editable={editable}
                                                            validate={isInValidationMode}
                                                            onResponseChanged={handleResponseChanged(question.id)}
                                                        />
                                                    );
                                                default:
                                                    return <span>Input not implemented</span>;
                                            }
                                        })();

                                        if (isSection) {
                                            cachedComponents = [
                                                <a
                                                    key={`${question.id}-a`}
                                                    id={`question-${canonicalizeHtmlId(question.id)}`}
                                                ></a>,
                                                questionElement
                                            ];

                                            return <></>;
                                        }

                                        const components = [
                                            ...cachedComponents,
                                            <a
                                                key={`${question.id}-a`}
                                                id={`question-${canonicalizeHtmlId(question.id)}`}
                                            ></a>,
                                            questionElement
                                        ];

                                        cachedComponents = [];

                                        return (
                                            <div key={`${question.id}-div`} style={{ breakInside: 'avoid' }}>
                                                {components}
                                            </div>
                                        );
                                    });
                                })()}
                                {editable && (
                                    <Box key={'submit'} sx={{ marginTop: '12px' }}>
                                        <a key="submit-a" id="submit"></a>
                                        <Button key="submit-button" onClick={handleSubmit}>
                                            Submit Application
                                        </Button>
                                    </Box>
                                )}
                            </>
                        </PrintableComponent>
                    </StyledBox>
                    <Dialog
                        key="submit-dialog"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{'Submit application?'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Once you have submitted your application, you can no longer make changes. Are you sure
                                you want to submit your application now?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            {isSubmitting && <CircularProgress size="20px" />}
                            <Button disabled={isSubmitting} onClick={handleClose(true)}>
                                Cancel
                            </Button>
                            <Button disabled={isSubmitting} onClick={handleClose(false)} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default ApplicationEditor;
