import { z } from 'zod';
import { ApplicationQuestionSchema } from './applicationQuestion';
import { ApplicationStatus } from './applicationStatus';

export const ApplicationSchema = z.object({
    id: z.string(),
    title: z.string(),
    utc_modified: z.number(),
    utc_created: z.number(),
    status: z.nativeEnum(ApplicationStatus),
    user_ids: z.array(z.string()),
    questions: z.array(ApplicationQuestionSchema),
    responses: z.record(z.string())
});
export type Application = z.infer<typeof ApplicationSchema>;
