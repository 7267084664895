import { z } from 'zod';
import { ApiResponseErrorCodes } from './apiResponseErrorCodes';

export const ApiErrorResponseSchema = z.object({
    status: z.literal('ERROR'),
    code: z.nativeEnum(ApiResponseErrorCodes),
    message: z.string(),
    detail: z.unknown().optional()
});

export const ApiSuccessResponseBaseSchema = z.object({
    status: z.literal('OK')
});
