//eslint-disable-next-line @typescript-eslint/ban-types
export default function areObjectsSame(k1?: Object, k2?: Object): boolean {
    if (!k1 && !k2) {
        return true;
    }

    if (!k1 || !k2) {
        return false;
    }

    return Object.entries(k1).toString() === Object.entries(k2).toString();
}
