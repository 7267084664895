import React, { useContext } from 'react';
import ApplicationsController from '../../services/api/applications';
import RegistrationController from '../../services/api/registration';
import UserController from '../../services/api/user';
import { AuthContext } from '../AuthProvider';

interface ApiContextProps {
    applications: ApplicationsController;
    registration: RegistrationController;
    user: UserController;
}

const Context = React.createContext<ApiContextProps>({
    applications: new ApplicationsController(),
    registration: new RegistrationController(),
    user: new UserController()
});

//eslint-disable-next-line @typescript-eslint/ban-types
const ApiProvider = (props: React.PropsWithChildren<{}>) => {
    const authContext = useContext(AuthContext);

    const value: ApiContextProps = {
        applications: new ApplicationsController(authContext.token, authContext.user),
        registration: new RegistrationController(),
        user: new UserController(authContext.token, authContext.user)
    };

    return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const ApiContext = Context;
export default ApiProvider;
