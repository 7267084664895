import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { ApiErrorResponseSchema, Application, MultipleSelectApplicationQuestion } from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import * as React from 'react';
import { ApiContext } from '../../../providers/ApiProvider';
import unused from '../../../util/unused';
import Subtext from './subtext';

export type MultipleSelectResponseProps = MultipleSelectApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function renderResponse(rawResponse: string): string {
    try {
        const parsedResponse = JSON.parse(rawResponse);

        if (typeof parsedResponse === 'string') {
            return parsedResponse;
        }

        if (Array.isArray(parsedResponse)) {
            return parsedResponse.sort().join(', ');
        }
    } catch (e) {
        //squelch
    }

    return '';
}

const MultipleSelectResponse: React.FC<MultipleSelectResponseProps> = (props: MultipleSelectResponseProps) => {
    const { prompt, id, responses, options, editable, applicationId, subtext, onResponseChanged } = props;
    const [response, setResponse] = React.useState(responses[id] || '[]');
    const selectedValues = JSON.parse(response);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);

    unused(saving, error);

    const handleMultiSelectValueChange = (event: SelectChangeEvent<string[]>) => {
        if (!editable) {
            return;
        }

        const {
            target: { value }
        } = event;

        const newValue = JSON.stringify(typeof value === 'string' ? [value] : value);

        saveToApi(newValue);
        setResponse(newValue);

        if (onResponseChanged) {
            onResponseChanged(newValue);
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(response.data.message);
                    } else {
                        setError('an unknown error occurred');
                    }
                } else {
                    setError('an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    return (
        <>
            <p>
                {prompt}
                {subtext && (
                    <>
                        <br />
                        <Subtext>{subtext}</Subtext>
                    </>
                )}
            </p>
            {editable && (
                <>
                    <FormControl fullWidth>
                        <InputLabel id={`multiple-select-label-${id}`}>Response</InputLabel>
                        <Select
                            labelId={`multiple-select-label-${id}`}
                            id={`multiple-select-${id}`}
                            multiple
                            value={selectedValues}
                            onChange={handleMultiSelectValueChange}
                            input={<OutlinedInput label="Response" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            fullWidth
                            MenuProps={MenuProps}
                        >
                            {options.map((option) => (
                                <MenuItem key={`multi-select-menu-item-${id}-${option}`} value={option}>
                                    <Checkbox checked={selectedValues?.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
            {!editable && (
                <>
                    <Typography variant="body1" component="span" sx={{ marginLeft: '24px', fontWeight: 'bold' }}>
                        Response:{' '}
                    </Typography>
                    <Typography variant="body1" component="span">
                        {renderResponse(response)}
                    </Typography>
                </>
            )}
        </>
    );
};

export default MultipleSelectResponse;
