import * as React from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Container,
    Grid,
    Stack,
    TextField
} from '@mui/material';
import { useEffect, useReducer } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import * as uuid from 'uuid';
import CursorLink from '../../components/CursorLink';
import { AxiosError } from 'axios';
import { ApiErrorResponseSchema, ApiResponseErrorCodes } from '@rqr/deal-flow-abstractions';
import logo from '../../static/img/logo.png';

type State = {
    username: string;
    password: string;
    isButtonDisabled: boolean;
    isAuthenticating: boolean;
    token: string;
    helperText: string;
    isError: boolean;
};

const initialState: State = {
    username: '',
    password: '',
    isButtonDisabled: true,
    isAuthenticating: false,
    token: localStorage.getItem('auth_token') || '',
    helperText: '',
    isError: false
};

type Action =
    | { type: 'setUsername'; payload: string }
    | { type: 'setPassword'; payload: string }
    | { type: 'setIsButtonDisabled'; payload: boolean }
    | { type: 'setIsAuthenticating'; payload: boolean }
    | { type: 'loginSuccess'; payload: string }
    | { type: 'loginFailed'; payload: string }
    | { type: 'setIsError'; payload: boolean }
    | { type: 'setToken'; payload: string };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'setUsername':
            return {
                ...state,
                username: action.payload
            };
        case 'setPassword':
            return {
                ...state,
                password: action.payload
            };
        case 'setToken':
            return {
                ...state,
                token: action.payload
            };
        case 'setIsButtonDisabled':
            return {
                ...state,
                isButtonDisabled: action.payload
            };
        case 'setIsAuthenticating':
            return {
                ...state,
                isAuthenticating: action.payload
            };
        case 'loginSuccess':
            return {
                ...state,
                helperText: action.payload,
                isError: false
            };
        case 'loginFailed':
            return {
                ...state,
                helperText: action.payload,
                isAuthenticating: false,
                token: '',
                isError: true
            };
        case 'setIsError':
            return {
                ...state,
                isError: action.payload
            };
    }
};

const Login = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { onLogin, tryLoadToken, clearToken } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (state.username.trim() && state.password.trim()) {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: false
            });
        } else {
            dispatch({
                type: 'setIsButtonDisabled',
                payload: true
            });
        }
    }, [state.username, state.password]);

    useEffect(() => {
        const originalPath = location.pathname;

        const loadToken = async () => {
            if (tryLoadToken) {
                const result = await tryLoadToken();

                const userApps = result.user.apps.map((app) => ({ title: app, route: app.toLowerCase() }));

                dispatch({
                    type: 'loginSuccess',
                    payload: 'login successful'
                });

                navigate(originalPath !== '/' ? originalPath : userApps[0].route);
            }
        };

        loadToken()
            .catch(() => {
                if (clearToken) {
                    return clearToken();
                }

                return undefined;
            })
            .then(() => {
                dispatch({
                    type: 'setToken',
                    payload: ''
                });
            });
    }, []);

    const handleLoginError = async (err: unknown): Promise<void> => {
        if (clearToken) {
            await clearToken();
        }

        if (err instanceof AxiosError) {
            const response = ApiErrorResponseSchema.safeParse(err.response?.data);

            if (response.success) {
                if (response.data.code === ApiResponseErrorCodes.AccountNotConfirmed) {
                    navigate(`/reconfirm/${encodeURIComponent(state.username)}`);

                    return;
                }

                if (response.data.code === ApiResponseErrorCodes.Unauthorized) {
                    dispatch({
                        type: 'loginFailed',
                        payload: 'invalid username or password'
                    });

                    return;
                }
            }
        }

        dispatch({
            type: 'loginFailed',
            payload: err instanceof Error ? err.message : (err as string)
        });
    };

    const handleLogin = async () => {
        const originalPath = location.pathname;

        try {
            dispatch({
                type: 'setIsAuthenticating',
                payload: true
            });

            if (!onLogin) {
                dispatch({
                    type: 'loginFailed',
                    payload: 'Auth not initialized'
                });
            } else {
                try {
                    const result = await onLogin(state.username, state.password);

                    if (result.token) {
                        localStorage.setItem('auth_token', JSON.stringify(result.token));
                    }

                    const userApps = result.user.apps.map((app) => ({ title: app, route: app.toLowerCase() }));

                    navigate(
                        originalPath !== '/login' && originalPath !== '/' ? originalPath : `/${userApps[0].route}`
                    );
                } catch (err) {
                    await handleLoginError(err);
                }
            }
        } finally {
            dispatch({
                type: 'setIsAuthenticating',
                payload: false
            });
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            state.isButtonDisabled || handleLogin();
        }
    };

    const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch({
            type: 'setUsername',
            payload: event.target.value
        });
    };

    const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        dispatch({
            type: 'setPassword',
            payload: event.target.value
        });
    };

    const handleForgotPassword = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        navigate(`/reset-request/${uuid.v4()}`);
    };

    if (state.token.length > 0) {
        return (
            <Stack alignItems="center" m={33}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 8 }}>
                <form noValidate autoComplete="off">
                    <Card elevation={3}>
                        <CardMedia
                            sx={{ padding: '32px', paddingBottom: '0px' }}
                            component="img"
                            image={logo}
                            alt="logo"
                        />
                        <CardContent>
                            <div>
                                <TextField
                                    error={state.isError}
                                    fullWidth
                                    id="username"
                                    type="email"
                                    label="Email"
                                    placeholder="Email"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    margin="normal"
                                    autoFocus
                                    disabled={state.isAuthenticating || Boolean(state.token)}
                                    onChange={handleUsernameChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <TextField
                                    error={state.isError}
                                    fullWidth
                                    id="password"
                                    type="password"
                                    label="Password"
                                    placeholder="Password"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    margin="normal"
                                    disabled={state.isAuthenticating || Boolean(state.token)}
                                    helperText={state.helperText}
                                    onChange={handlePasswordChange}
                                    onKeyPress={handleKeyPress}
                                />
                                <Box textAlign="right" sx={{ width: '100%' }}>
                                    <CursorLink onClick={handleForgotPassword}>forgot password</CursorLink>
                                </Box>
                            </div>
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={handleLogin}
                                        disabled={
                                            state.isButtonDisabled || state.isAuthenticating || Boolean(state.token)
                                        }
                                    >
                                        Login
                                    </Button>
                                </Grid>
                                {(state.isAuthenticating || Boolean(state.token)) && (
                                    <Grid item xs={2}>
                                        <CircularProgress size={32} />
                                    </Grid>
                                )}
                            </Grid>
                        </CardActions>
                    </Card>
                </form>
            </Box>
        </Container>
    );
};

export default Login;
