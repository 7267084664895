import { DataGridPro, GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Breadcrumbs, Chip } from '@mui/material';
import { ApiContext } from '../../providers/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { styled } from '@mui/material/styles';
import { Drafts, FileOpenOutlined, Grading } from '@mui/icons-material';
import { ApplicationDataSummary, ApplicationStatus } from '@rqr/deal-flow-abstractions';

const StyledFileOpenOutlined = styled(FileOpenOutlined)(() => ({
    cursor: 'pointer'
}));

const StyledChip = styled(Chip)(() => ({
    justifyContent: 'left',
    '& .icon': {
        color: 'inherit'
    },
    [`&.${ApplicationStatus.Draft}`]: {
        color: 'goldenrod',
        border: '1px solid goldenrod'
    },
    // [`&.${ApplicationStatus.Approved}`]: {
    //     color: 'green',
    //     border: '1px solid green',
    // },
    [`&.${ApplicationStatus.InReview}`]: {
        color: 'blue',
        border: '1px solid blue'
    }
    // [`&.${ApplicationStatus.Rejected}`]: {
    //     color: 'red',
    //     border: '1px solid red',
    // },
}));

interface StatusProps {
    status: ApplicationStatus;
}

const Status = React.memo((props: StatusProps) => {
    const { status } = props;

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    let icon: any = null;
    let label: string = status;
    // if (status === ApplicationStatus.Rejected) {
    //     icon = <GppBad className="icon" />;
    // } else
    if (status === ApplicationStatus.InReview) {
        icon = <Grading className="icon" />;
        label = 'In Review';
    } else if (status === ApplicationStatus.Draft) {
        icon = <Drafts className="icon" />;
    }
    // else if (status === ApplicationStatus.Approved) {
    //     icon = <Check className="icon" />;
    // }

    return <StyledChip className={status} icon={icon} size="small" label={label} variant="outlined" />;
});

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function stringifyDateTime(value: any): string {
    if (value instanceof DateTime) {
        return value.toLocaleString(DateTime.DATETIME_SHORT);
    }

    if (typeof value === 'number') {
        return value < 0 ? 'never' : DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT);
    }

    return value?.toString();
}

const ApplicationsList = () => {
    const [applications, setApplications] = React.useState<ApplicationDataSummary[]>();
    const api = React.useContext(ApiContext);
    const navigate = useNavigate();

    const handleClick = (id: string) => () => {
        try {
            navigate(`view/${id}`);
        } catch (e) {
            console.error(e);
        }
    };

    const dataGridColumns: GridColDef[] = [
        {
            field: 'title',
            headerName: 'Title',
            width: 150,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => <Status status={params.value} />,
            width: 150
        },
        {
            field: 'utc_created',
            headerName: 'Created',
            valueFormatter: (value, row) => stringifyDateTime(row.utc_created),
            width: 160
        },
        {
            field: 'utc_modified',
            headerName: 'Modified',
            valueFormatter: (value, row) => stringifyDateTime(row.utc_created),
            width: 160
        },
        {
            field: 'actions',
            headerName: '',
            renderCell: (params) => <StyledFileOpenOutlined onClick={handleClick(params.row.id as string)} />,
            width: 25
        }
    ];

    React.useEffect(() => {
        (async () => {
            const result = await api.applications.listApplications();

            setApplications(result);
        })();
    }, []);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Submissions</Typography>
            </Breadcrumbs>
            <br />
            <DataGridPro
                columns={dataGridColumns}
                rows={applications || []}
                loading={!applications}
                rowHeight={38}
                checkboxSelection
                disableRowSelectionOnClick
                slots={{
                    toolbar: GridToolbar
                }}
            />
        </>
    );
};

export default ApplicationsList;
