import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { UserPublicDataSchema } from './userPublicData';

export const GetUserSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        user: UserPublicDataSchema
    })
);

export const GetUserResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    GetUserSuccessResponseSchema
]);
export type GetUserResponse = z.infer<typeof GetUserResponseSchema>;
