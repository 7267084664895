import {
    AuthTokenData,
    CreateAuthResetInput,
    CreateAuthResetResponse,
    GetUserResponse,
    ListUsersData,
    ListUsersResponse,
    UpdateUserPasswordInput,
    UpdateUserPasswordResponse,
    UserPublicData,
    UpdateUserInput,
    UpdateUserResponse
} from '@rqr/deal-flow-abstractions';
import axios from 'axios';

export type ResetCodeData = {
    completion_code: string;
    request_id: string;
    user_id: string;
};

export type ListUsersResult = {
    users: UserPublicData[];
};

export type GetUserResult = {
    user: UserPublicData;
};

const OK = 'OK';
const IMPOSSIBLE_CONDITION = 'impossible condition';

export default class UserController {
    private readonly token?: AuthTokenData;
    private readonly user?: UserPublicData;

    public constructor(token?: AuthTokenData, user?: UserPublicData) {
        this.token = token;
        this.user = user;
    }

    public async getMe(): Promise<UserPublicData> {
        if (!this.token || !this.user) {
            throw new Error(`GET /users/me requires authentication`);
        }

        const result = await axios.get<GetUserResponse>(`${process.env.REACT_APP_API_BASE}/api/users/me`, {
            headers: {
                authorization: `Bearer ${this.token.access_token}`
            }
        });

        if (result.data.status === OK) {
            return result.data.user;
        }

        throw new Error(IMPOSSIBLE_CONDITION);
    }

    public async updateUser(userId: string, changes: UpdateUserInput): Promise<UpdateUserResponse> {
        if (!this.token || !this.user) {
            throw new Error(`PATCH /users/${userId} requires authentication`);
        }

        const result = await axios.patch<UpdateUserPasswordResponse>(
            `${process.env.REACT_APP_API_BASE}/api/users/${userId}`,
            changes,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        return result.data;
    }

    public async getUserById(id: string): Promise<UserPublicData> {
        if (!this.token || !this.user) {
            throw new Error(`GET /users/${id} requires authentication`);
        }

        const result = await axios.get<GetUserResponse>(
            `${process.env.REACT_APP_API_BASE}/api/users/${encodeURIComponent(id)}`,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        if (result.data.status === OK) {
            return result.data.user;
        }

        throw new Error(IMPOSSIBLE_CONDITION);
    }

    public async listUsers(): Promise<ListUsersData> {
        if (!this.token || !this.user) {
            throw new Error(`GET /applications requires authentication`);
        }

        const result = await axios.get<ListUsersResponse>(`${process.env.REACT_APP_API_BASE}/api/users`, {
            headers: {
                authorization: `Bearer ${this.token.access_token}`
            }
        });

        if (result.data.status === OK) {
            return result.data.users;
        }

        throw new Error(IMPOSSIBLE_CONDITION);
    }

    public async confirmUser(): Promise<void> {
        throw new Error();
    }

    public async createResetRequest(requestId: string, email: string): Promise<CreateAuthResetResponse> {
        //ensure changes to API definition cause a compilation failure
        const payload: CreateAuthResetInput = {
            email
        };

        const result = await axios.post<CreateAuthResetResponse>(
            `${process.env.REACT_APP_API_BASE}/api/auth/reset`,
            payload
        );

        return result.data;
    }

    public async resetUserPassword(resetCode: string, password: string): Promise<UpdateUserPasswordResponse> {
        //ensure changes to API definition cause a compilation failure
        const payload: UpdateUserPasswordInput = {
            resetCode,
            password
        };

        const result = await axios.put<UpdateUserPasswordResponse>(
            `${process.env.REACT_APP_API_BASE}/api/user/password`,
            payload
        );

        return result.data;
    }
}
