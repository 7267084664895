import { Link, Typography } from '@mui/material';

const Copyright = () => {
    const company = process.env.REACT_APP_COMPANY_NAME || 'Company, Inc.';

    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://website.com">
                {company}
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
};

export default Copyright;
