import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const LegalApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('legal'),
        text: z.string(),
        agreementUrl: z.string(),
        prompt: z.string()
    })
);
export type LegalApplicationQuestion = z.infer<typeof LegalApplicationQuestionSchema>;
