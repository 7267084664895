import * as React from 'react';
import ResponsiveAppBar from '../../components/ResponsiveAppBar';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';
import Login from '../Login';
import Register from '../Register';
import ResetRequest from '../ResetRequest';
import ResetComplete from '../ResetComplete';
import Copyright from '../../components/Copyright';
import RegisterConfirm from '../RegisterConfirm';
import Reconfirm from '../Reconfirm';
import Bootstrap from '../Bootstrap';
import { Apps } from '@rqr/deal-flow-abstractions';

const settings = ['Account', 'Logout'];

const Layout = () => {
    const { token, user } = React.useContext(AuthContext);
    const userApps = (user?.apps.map((app) => ({ title: app, route: app.toLowerCase() })) || []).sort((a, b) => {
        //sort applications across the nav menu to be the same as the order
        //specified by deal-flow-abstractions
        return Apps.indexOf(a.title) - Apps.indexOf(b.title);
    });

    // React.useEffect(() => {
    //     if (window.location.pathname === '/') {
    //         //there is no path, route to registration screen
    //         navigate(`/register/${process.env.REACT_APP_DEFAULT_REGISTRATION_CODE}`);
    //     }
    // }, []);

    if (token) {
        return (
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box>
                    <ResponsiveAppBar
                        pages={userApps}
                        settings={settings.map((setting) => ({ title: setting, route: setting.toLowerCase() }))}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, padding: '32px', overflowY: 'auto', scrollBehavior: 'smooth' }}>
                    <Container sx={{ size: 'lg', height: 'calc(100% - 68.5px)' }}>
                        <Outlet />
                    </Container>
                </Box>
                <Box>
                    <Copyright />
                </Box>
            </Box>
        );
    } else {
        return (
            <Routes>
                <Route path="register/:invitationCode" element={<Register />} />
                <Route path="register-confirm/:confirmationCode" element={<RegisterConfirm />} />
                <Route path="reset-request/:id" element={<ResetRequest />} />
                <Route path="reset-complete/:resetCode" element={<ResetComplete />} />
                <Route path="reconfirm/:username" element={<Reconfirm />} />
                <Route path="/login" element={<Login />} />
                <Route path="/*" element={<Bootstrap />} />
            </Routes>
        );
    }
};

export default Layout;
