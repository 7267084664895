import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const GetStatusSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const GetStatusResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    GetStatusSuccessResponseSchema
]);
export type GetStatusResponse = z.infer<typeof GetStatusResponseSchema>;
