//eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export default function* getChangedProperties(k1?: Object, k2?: Object): Iterable<[string, any]> {
    if (!k1 && !k2) {
        return;
    }

    if (!k1 || !k2) {
        return;
    }

    for (const [key, value] of Object.entries(k1)) {
        const k2Value = Reflect.get(k2, key);

        if (value !== k2Value) {
            yield [key, value];
        }
    }
}
