export enum ApiResponseErrorCodes {
    NotFound = 'NotFound',
    AmbiguousObject = 'AmbiguousObject',
    BadRequest = 'BadRequest',
    Unknown = 'Unknown',
    ObjectAlreadyExists = 'ObjectAlreadyExists',
    DatabaseError = 'DatabaseError',
    ResourceExpired = 'ResourceExpired',
    NotImplemented = 'NotImplemented',
    AccountNotConfirmed = 'AccountNotConfirmed',
    AccountDisabled = 'AccountDisabled',
    Unauthorized = 'Unauthorized',
    AccessTokenExpired = 'AccessTokenExpired',
    AccessTokenNotFound = 'AccessTokenNotFound'
}
