import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { ApplicationSchema } from './application';

export const ApplicationDataSummarySchema = ApplicationSchema.omit({
    questions: true,
    responses: true
});
export type ApplicationDataSummary = z.infer<typeof ApplicationDataSummarySchema>;

export const ListApplicationsDataSchema = z.array(ApplicationDataSummarySchema);

export type ListApplicationsData = z.infer<typeof ListApplicationsDataSchema>;

export const ListApplicationsSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        applications: ListApplicationsDataSchema
    })
);

export const ListApplicationResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    ListApplicationsSuccessResponseSchema
]);
export type ListApplicationsResponse = z.infer<typeof ListApplicationsSuccessResponseSchema>;
