import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const AuthTokenDataSchema = z.object({
    access_token: z.string(),
    token_type: z.string(),
    expires_in: z.number(),
    scope: z.string(),
    refresh_token: z.string()
});
export type AuthTokenData = z.infer<typeof AuthTokenDataSchema>;

export const CreateTokenSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(AuthTokenDataSchema);

export const CreateTokenResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    CreateTokenSuccessResponseSchema
]);
export type CreateTokenResponse = z.infer<typeof CreateTokenResponseSchema>;
