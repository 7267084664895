import {
    ApiErrorResponseSchema,
    ApiResponseErrorCodes,
    AuthTokenData,
    UserPublicData
} from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import AuthController from '../../services/api/auth';

let initResolve: undefined | ((result: Required<Pick<AuthContextProps, 'token' | 'user'>> | undefined) => void);
const initPromise = new Promise<Required<Pick<AuthContextProps, 'token' | 'user'>> | undefined>(
    (resolve) => (initResolve = resolve)
);

type AuthTokenDataExtended = AuthTokenData & {
    utc_expires_at: number;
};

function makeExtendedTokenData(tokenData: AuthTokenData): AuthTokenDataExtended {
    return {
        ...tokenData,
        utc_expires_at: Date.now() + tokenData.expires_in * 1000
    };
}

interface AuthContextProps {
    user?: UserPublicData;
    token?: AuthTokenDataExtended;
    onLogin?: (username: string, password: string) => Promise<Required<Pick<AuthContextProps, 'token' | 'user'>>>;
    onLogout?: () => void;
    tryLoadToken?: () => Promise<Required<Pick<AuthContextProps, 'token' | 'user'>>>;
    tryRefreshToken?: () => Promise<Required<Pick<AuthContextProps, 'token' | 'user'>>>;
    clearToken?: () => Promise<void>;
    ready: Promise<Required<Pick<AuthContextProps, 'token' | 'user'>> | undefined>;
}

const Context = React.createContext<AuthContextProps>({
    token: undefined,
    user: undefined,
    onLogin: undefined,
    onLogout: undefined,
    tryLoadToken: undefined,
    ready: initPromise
});

//eslint-disable-next-line @typescript-eslint/ban-types
const AuthProvider = (props: React.PropsWithChildren<{}>) => {
    const [token, setToken] = React.useState<AuthTokenDataExtended | undefined>(undefined);
    const [user, setUser] = React.useState<UserPublicData>();
    const [refreshTimer, setRefreshTimer] = React.useState<NodeJS.Timer>();

    React.useEffect(() => {
        (async () => {
            try {
                initResolve && initResolve(await tryLoadToken());
            } catch (e) {
                initResolve && initResolve(undefined);
            }
        })();
    }, []);

    useEffect(() => {
        if (refreshTimer) {
            clearTimeout(refreshTimer);
        }

        let newTimer: NodeJS.Timer | undefined = undefined;

        if (token) {
            const whenToRefresh = token.utc_expires_at - 30000;
            const refreshInMsec = whenToRefresh - Date.now();

            newTimer = setTimeout(tryRefreshToken, refreshInMsec);
        }

        setRefreshTimer(newTimer);
    }, [token]);

    const handleLogin = async (username: string, password: string) => {
        const authResult = await AuthController.authenticate(username, password);

        if (authResult.status === 'ERROR') {
            throw new Error(authResult.message);
        }

        const userData = await AuthController.interrogate(authResult.access_token);

        const extendedTokenData = makeExtendedTokenData(authResult);

        localStorage.setItem('auth_token', JSON.stringify(extendedTokenData));

        setToken(extendedTokenData);
        setUser(userData);

        return {
            token: extendedTokenData,
            user: userData
        };
    };

    const handleLogout = () => {
        localStorage.removeItem('auth_token');

        setToken(undefined);
        setUser(undefined);
    };

    const tryRefreshToken = async (): Promise<Required<Pick<AuthContextProps, 'token' | 'user'>>> => {
        const storedToken = localStorage.getItem('auth_token');

        if (storedToken) {
            const decodedToken = JSON.parse(storedToken) as AuthTokenDataExtended;

            const token = await AuthController.refreshToken(decodedToken.refresh_token);

            if (token.status === 'ERROR') {
                //because axios throws
                throw new Error('impossible condition');
            }

            const user = await AuthController.interrogate(token.access_token);
            const extendedTokenData = makeExtendedTokenData(token);

            localStorage.setItem('auth_token', JSON.stringify(extendedTokenData));

            setToken(extendedTokenData);
            setUser(user);

            return {
                token: extendedTokenData,
                user
            };
        }

        throw new Error('invalid token or not token present');
    };

    const tryLoadToken = async (): Promise<Required<Pick<AuthContextProps, 'token' | 'user'>>> => {
        const storedToken = localStorage.getItem('auth_token');

        if (storedToken) {
            const decodedToken = JSON.parse(storedToken) as AuthTokenDataExtended;
            let user: UserPublicData;

            try {
                //query API to get the data
                user = await AuthController.interrogate(decodedToken.access_token);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success && response.data.code === ApiResponseErrorCodes.AccessTokenExpired) {
                        return await tryRefreshToken();
                    }
                }
                throw e;
            }

            setToken(decodedToken);
            setUser(user);

            return {
                token: decodedToken,
                user
            };
        }

        throw new Error('invalid token or not token present');
    };

    const clearToken = async (): Promise<void> => {
        localStorage.removeItem('auth_token');
    };

    const value: AuthContextProps = {
        token,
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        tryLoadToken,
        tryRefreshToken,
        clearToken,
        ready: initPromise
    };

    return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export const AuthContext = Context;
export default AuthProvider;
