import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const UpdateUserPasswordSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const UpdateUserPasswordResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    UpdateUserPasswordSuccessResponseSchema
]);
export type UpdateUserPasswordResponse = z.infer<typeof UpdateUserPasswordResponseSchema>;
