import { Breadcrumbs, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ApplicationEditor from '../../components/ApplicationEditor';
import CursorLink from '../../components/CursorLink';

const ViewApplication = () => {
    const navigate = useNavigate();

    const navigateTo = (route: string) => () => navigate(route);

    return (
        <ApplicationEditor
            breadcrumbs={(application) => (
                <Breadcrumbs aria-label="breadcrumb">
                    <CursorLink underline="hover" color="inherit" onClick={navigateTo('..')}>
                        My Applications
                    </CursorLink>
                    <Typography color="text.primary">{application.title}</Typography>
                </Breadcrumbs>
            )}
        />
    );
};

export default ViewApplication;
