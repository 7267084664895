import {
    CreateRegistrationResponse,
    GetRegistrationCodeResponse,
    CreateRegistrationInput,
    ConfirmRegistrationInput,
    UserPublicData,
    CreateConfirmationResponse,
    CreateConfirmationInput
} from '@rqr/deal-flow-abstractions';
import axios from 'axios';

export default class RegistrationController {
    public async getRegistration(invitationCode: string): Promise<GetRegistrationCodeResponse> {
        const result = await axios.get<GetRegistrationCodeResponse>(
            `${process.env.REACT_APP_API_BASE}/api/registration-code/${encodeURIComponent(invitationCode)}`
        );
        return result.data;
    }

    public async confirmRegistration(email: string, confirmationCode: string): Promise<CreateRegistrationResponse> {
        const payload: ConfirmRegistrationInput = { email };

        const result = await axios.post<CreateRegistrationResponse>(
            `${process.env.REACT_APP_API_BASE}/api/registration/confirm/${confirmationCode}`,
            payload
        );

        return result.data;
    }

    public async createConfirmation(email: string): Promise<CreateConfirmationResponse> {
        const payload: CreateConfirmationInput = { email };

        const result = await axios.post<CreateConfirmationResponse>(
            `${process.env.REACT_APP_API_BASE}/api/registration/confirm`,
            payload
        );

        return result.data;
    }

    public async registerUser(
        invitationCode: string,
        userData: Omit<UserPublicData, 'id' | 'status'>,
        password: string
    ): Promise<CreateRegistrationResponse> {
        //ensure changes to API definition cause a compilation failure
        const payload: CreateRegistrationInput = {
            email: userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name,
            password: password,
            registration_code: invitationCode
        };

        const result = await axios.post<CreateRegistrationResponse>(
            `${process.env.REACT_APP_API_BASE}/api/registration`,
            payload
        );

        return result.data;
    }
}
