import { CreateTokenInput, CreateTokenResponse, GetMeResponse, UserPublicData } from '@rqr/deal-flow-abstractions';
import axios from 'axios';

const AuthController = {
    async authenticate(username: string, password: string): Promise<CreateTokenResponse> {
        //ensure changes to API definition cause a compilation failure
        const payload: CreateTokenInput = {
            grant_type: 'password',
            client_id: 'Pi60PvzHsXSqzQL4cYcaOqexTy5qBktuR4IPGol3',
            username,
            password,
            scope: 'read,write'
        };

        const result = await axios.post<CreateTokenResponse>(
            `${process.env.REACT_APP_API_BASE}/api/auth/token`,
            payload
        );

        return result.data;
    },
    async interrogate(token: string): Promise<UserPublicData> {
        const result = await axios.get<GetMeResponse>(`${process.env.REACT_APP_API_BASE}/api/auth/me`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });

        if (result.data.status === 'OK') {
            return result.data.me;
        }

        throw new Error('impossible condition');
    },
    async refreshToken(token: string): Promise<CreateTokenResponse> {
        //ensure changes to API definition cause a compilation failure
        const payload: CreateTokenInput = {
            grant_type: 'refresh_token',
            client_id: 'Pi60PvzHsXSqzQL4cYcaOqexTy5qBktuR4IPGol3',
            refresh_token: token
        };

        const result = await axios.post<CreateTokenResponse>(
            `${process.env.REACT_APP_API_BASE}/api/auth/token`,
            payload
        );

        return result.data;
    }
};

export default AuthController;
