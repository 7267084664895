import { Breadcrumbs, Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../providers/ApiProvider';
import CursorLink from '../../components/CursorLink';

const NewApplicationForm = () => {
    const [title, setTitle] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const navigate = useNavigate();
    const api = React.useContext(ApiContext);

    const navigateTo = (route: string) => () => navigate(route);
    const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setTitle(event.target.value);
    };
    const handleClickCreate = () => {
        setIsSubmitting(true);

        (async () => {
            try {
                const result = await api.applications.createApplication(title);

                if (result.status === 'ERROR') {
                    throw new Error('impossible condition');
                }

                navigate(`../view/${result.application.id}`);
            } catch (e) {
                //applicationId
            }
        })();
    };
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.keyCode === 13 || event.which === 13) {
            event.preventDefault();

            isSubmitting || handleClickCreate();
        }
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
                <CursorLink underline="hover" color="inherit" onClick={navigateTo('..')}>
                    Your Applications
                </CursorLink>
                <Typography color="text.primary">New Application</Typography>
            </Breadcrumbs>
            <form>
                <Paper sx={{ padding: '8px' }}>
                    <Typography>Create a new application for consideration.</Typography>
                    <br />
                    <Box sx={{ display: 'flex', marginLeft: '24px', marginRight: '24px' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextField
                                fullWidth
                                id="password"
                                label="Title"
                                placeholder="Title"
                                margin="normal"
                                helperText="Enter a title for your application."
                                onChange={handleTitleChange}
                                onKeyPress={handleKeyPress}
                            />
                        </Box>
                    </Box>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Button disabled={isSubmitting || !title || title.length < 1} onClick={handleClickCreate}>
                                Create
                            </Button>
                        </Grid>
                        {isSubmitting && (
                            <Grid item xs={2}>
                                <CircularProgress size={32} />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </form>
        </>
    );
};

export default NewApplicationForm;
