import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const MultipleSelectApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('multipleSelect'),
        prompt: z.string(),
        subtext: z.string().optional(),
        options: z.array(z.string())
    })
);
export type MultipleSelectApplicationQuestion = z.infer<typeof MultipleSelectApplicationQuestionSchema>;
