import { z } from 'zod';
import { ApplicationStatus } from './applicationStatus';

export const UpdateApplicationInputSchema = z
    .object({
        title: z.string(),
        status: z.nativeEnum(ApplicationStatus),
        user_ids: z.array(z.string()),
        responses: z.record(z.string())
    })
    .partial()
    .strict()
    .refine((data) => data.title || data.status || data.user_ids || data.responses, 'One or more changes must be made');

export type UpdateApplicationInput = z.infer<typeof UpdateApplicationInputSchema>;
