import { Add } from '@mui/icons-material';
import { Box, Typography, Fab } from '@mui/material';
import * as React from 'react';

interface NewApplicationCardProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const NewApplicationCard: React.FC<NewApplicationCardProps> = (props: NewApplicationCardProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box
                sx={{
                    height: '80px',
                    width: '80px',
                    display: 'flex',
                    flexShrink: 0,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Fab color="primary" aria-label="add" size="medium" onClick={props.onClick}>
                    <Add />
                </Fab>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography>create new application</Typography>
            </Box>
        </Box>
    );
};

export default NewApplicationCard;
