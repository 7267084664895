import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import Applications from './views/Applications';
import AuthProvider from './providers/AuthProvider';
import Layout from './views/Layout';
import Logout from './views/Logout';
import Submissions from './views/Submissions';
import Users from './views/Users';
import ApiProvider from './providers/ApiProvider';
import Account from './views/Account';
import axios from 'axios';
import { GetStatusResponse } from '@rqr/deal-flow-abstractions';

export default function App() {
    React.useEffect(() => {
        const pingApi = async () => {
            try {
                await axios.get<GetStatusResponse>(`${process.env.REACT_APP_API_BASE}/api/status`);
            } catch (e) {
                console.error(e);
            }
        };

        const interval = setInterval(pingApi, 15000);

        pingApi().then();

        return () => clearInterval(interval);
    }, []);

    return (
        <AuthProvider>
            <ApiProvider>
                <Routes>
                    <Route path="/*" element={<Layout />}>
                        <Route path="applications/*" element={<Applications />} />
                        <Route path="submissions/*" element={<Submissions />} />
                        <Route path="users" element={<Users />} />
                        <Route path="users/:id" element={<Users />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="account" element={<Account />} />
                    </Route>
                </Routes>
            </ApiProvider>
        </AuthProvider>
    );
}
