import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const context = React.useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (context.onLogout) {
            context.onLogout();
        }

        navigate('/login');
    });

    return (
        <Typography variant="h4" component="h1" gutterBottom>
            Logging out...
        </Typography>
    );
};

export default Logout;
