import * as React from 'react';
import Typography from '@mui/material/Typography';
import ApplicationCardSkeleton from './applicationCardSkeleton';
import { Breadcrumbs, List, ListItem } from '@mui/material';
import ApplicationCard from './applicationCard';
import NewApplicationCard from './newApplicationCard';
import { ApiContext } from '../../providers/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { ListApplicationsData } from '@rqr/deal-flow-abstractions';

const ApplicationsList = () => {
    const [applications, setApplications] = React.useState<ListApplicationsData>();
    const api = React.useContext(ApiContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            const result = await api.applications.listApplications();

            setApplications(result);
        })();
    }, []);

    const navigateTo = (route: string) => () => navigate(route);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Your Applications</Typography>
            </Breadcrumbs>
            {applications && (
                <List>
                    {applications.map((application) => (
                        <ListItem key={application.id}>
                            <ApplicationCard data={application} onClick={navigateTo(`view/${application.id}`)} />
                        </ListItem>
                    ))}
                    <ListItem key={'new-application-id'}>
                        <NewApplicationCard onClick={navigateTo('new')} />
                    </ListItem>
                </List>
            )}
            {!applications && (
                <List>
                    {Array.from(Array(3).keys()).map((i) => (
                        <ListItem key={`${i}`}>
                            <ApplicationCardSkeleton />
                        </ListItem>
                    ))}
                </List>
            )}
        </>
    );
};

export default ApplicationsList;
