import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../providers/ApiProvider';
import styled from '@emotion/styled';
import { ReportProblem } from '@mui/icons-material';
import Copyright from '../../components/Copyright';
import PasswordField from '../../components/PasswordField';
import { AxiosError } from 'axios';
import { ApiErrorResponseSchema, ApiResponseErrorCodes, UserRole } from '@rqr/deal-flow-abstractions';
import logo from '../../static/img/logo.png';

const StyledReportProblem = styled(ReportProblem)(() => ({
    color: 'red'
}));

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatApiError(error: any): string {
    if (error instanceof AxiosError && error.response?.data?.code === ApiResponseErrorCodes.NotFound) {
        return 'the invitation code provided is invalid';
    }

    return error.message;
}

const Register = () => {
    const { invitationCode } = useParams();
    const api = React.useContext(ApiContext);
    const [error, setError] = React.useState<Error>();
    const [emailError, setEmailError] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);
    const [agreeToTerms, setAgreeToTerms] = React.useState(false);

    React.useEffect(() => {
        (async (code?: string) => {
            if (!code) {
                //should be impossible
                setError(new Error('no invitation code was provided'));
                setIsLoading(false);

                return;
            }

            try {
                const result = await api.registration.getRegistration(code);

                if (result.status === 'ERROR') {
                    throw new Error(result.message);
                } else if (!result.registration_code.enabled) {
                    throw new Error('the invitation code is not enabled');
                }

                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);

                if (e instanceof Error) {
                    setError(e);
                } else if (typeof e === 'string') {
                    setError(new Error(e));
                } else {
                    setError(new Error('an unkown error has occured'));
                }
            }
        })(invitationCode);
    }, []);

    //eslint-disable-next-line sonarjs/cognitive-complexity
    const registerUser = async (event: React.FormEvent<HTMLFormElement>) => {
        if (!invitationCode) {
            throw new Error('invalid invitation code');
        }

        const data = new FormData(event.currentTarget);

        try {
            const password = data.get('password')?.toString();

            if (!password) {
                throw new Error('invalid password');
            }

            await api.registration.registerUser(
                invitationCode,
                {
                    first_name: data.get('firstName')?.toString() || '',
                    last_name: data.get('lastName')?.toString() || '',
                    utc_last_login: 0,
                    utc_created: Date.now(),
                    email: data.get('email')?.toString() || '',
                    role: UserRole.Applicant,
                    apps: ['Applications']
                },
                password
            );

            localStorage.setItem('registration_email', data.get('email')?.toString() || '');

            setIsComplete(true);
        } catch (e) {
            if (e instanceof AxiosError) {
                const error = ApiErrorResponseSchema.safeParse(e.response?.data);

                if (!error.success) {
                    console.log(error);
                    setError(new Error('the server returned an un-expected result'));
                } else {
                    if (error.data.code === ApiResponseErrorCodes.ObjectAlreadyExists) {
                        setEmailError('invalid email address or address is already in use');
                    }
                }
            } else {
                setError(new Error('an unknown error has occurred'));
            }

            setIsSubmitting(false);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!event.currentTarget.reportValidity()) {
            return;
        }

        setIsSubmitting(true);

        registerUser(event);
    };

    const handleAgreeToTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgreeToTerms(!(event.target.value === 'true'));
    };

    if (isLoading) {
        return (
            <Stack alignItems="center" m={33}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <>
            {!error && isComplete && (
                <>
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <img src={logo} style={{ maxWidth: '150px', paddingBottom: '16px' }} />
                            <Typography component="h1" variant="h5">
                                Sign Up
                            </Typography>
                            <br />
                            <Typography>Please check your email to complete registration.</Typography>
                        </Box>
                        <br />
                        <Copyright />
                    </Container>
                </>
            )}
            {!isComplete && (
                <>
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <img src={logo} style={{ maxWidth: '150px', paddingBottom: '16px' }} />
                            <Typography component="h1" variant="h5">
                                Sign up
                            </Typography>
                            {error && (
                                <>
                                    <Box sx={{ display: 'flex', marginTop: '18px' }}>
                                        <StyledReportProblem fontSize="large" />
                                        <Box sx={{ marginLeft: '12px' }}>
                                            <Typography>An error occurred registering you:</Typography>
                                            <Typography>{formatApiError(error)}</Typography>
                                        </Box>
                                    </Box>
                                </>
                            )}
                            {!error && (
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoComplete="given-name"
                                                name="firstName"
                                                required
                                                fullWidth
                                                id="firstName"
                                                label="First Name"
                                                disabled={isSubmitting}
                                                inputProps={{
                                                    minLength: 1,
                                                    maxLength: 64
                                                }}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="lastName"
                                                label="Last Name"
                                                name="lastName"
                                                disabled={isSubmitting}
                                                inputProps={{
                                                    minLength: 1,
                                                    maxLength: 64
                                                }}
                                                autoComplete="family-name"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email Address"
                                                name="email"
                                                type="email"
                                                autoComplete="email"
                                                disabled={isSubmitting}
                                                error={Boolean(emailError)}
                                                helperText={emailError}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PasswordField
                                                visible={false}
                                                buttonDisabled={false}
                                                required
                                                fullWidth
                                                disabled={isSubmitting}
                                                id="password"
                                                name="password"
                                                label="Password"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="agreeToTerms"
                                                        value={agreeToTerms}
                                                        checked={agreeToTerms}
                                                        onChange={handleAgreeToTermsChange}
                                                        disabled={isSubmitting}
                                                        color="primary"
                                                    />
                                                }
                                                label="I agree to the site terms and services."
                                            />
                                        </Grid>
                                    </Grid>
                                    {isSubmitting && (
                                        <Stack alignItems="center" sx={{ marginTop: '12px', marginBottom: '24px' }}>
                                            <CircularProgress />
                                        </Stack>
                                    )}
                                    {!isSubmitting && (
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            disabled={!agreeToTerms}
                                            sx={{ mt: 3, mb: 2 }}
                                        >
                                            Sign Up
                                        </Button>
                                    )}
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Link href="/login" variant="body2">
                                                Already have an account? Sign in
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                        <br />
                        <Copyright />
                    </Container>
                </>
            )}
        </>
    );
};

export default Register;
