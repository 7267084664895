import styled from '@emotion/styled';
import { Cancel, Check } from '@mui/icons-material';
import {
    IconButton,
    Input,
    InputAdornment,
    InputProps,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps
} from '@mui/material';
import * as React from 'react';

export interface InlineEditFieldBaseProps {
    value: string;
    onChange: (value: string) => void;
}

export interface InlineEditTextFieldProps {
    type: 'text';
    inputProps?: InputProps;
}

export interface InlineEditSelectProps {
    type: 'select';
    options?: string[];
    inputProps?: SelectProps;
}

export type InlineEditFieldProps = InlineEditFieldBaseProps & (InlineEditTextFieldProps | InlineEditSelectProps);

const HoverLabel = styled('div')(() => ({
    '&:hover': {
        backgroundColor: '#e6e6e6',
        cursor: 'pointer'
    }
}));

const InlineEditField: React.FC<InlineEditFieldProps> = (props: InlineEditFieldProps) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [currentValue, setCurrentValue] = React.useState(props.value);
    const formRef = React.useRef<HTMLFormElement>(null);

    const handleOnLabelClick = () => {
        setIsEditing(true);
    };

    //#region TextField functionality
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(event.target.value);
    };

    const handleTextKeyPress = (event: React.KeyboardEvent) => {
        if (!isEditing) {
            return;
        }

        if (event.keyCode === 13 || event.which === 13) {
            event.preventDefault();

            if (formRef.current && !formRef.current.reportValidity()) {
                return;
            }

            setIsEditing(false);
            props.onChange(currentValue);
        }
    };

    const escFunction = React.useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsEditing(false);
            setCurrentValue(props.value);
        }
    }, []);
    //#endregion

    React.useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    const handleSaveClicked = () => {
        if (!isEditing) {
            return;
        }

        if (formRef.current && !formRef.current.reportValidity()) {
            return;
        }

        setIsEditing(false);
        props.onChange(currentValue);
    };

    const handleCancelClicked = () => {
        if (!isEditing) {
            return;
        }

        setIsEditing(false);
        setCurrentValue(props.value);
    };

    const handleSelection = (event: SelectChangeEvent) => {
        if (!isEditing) {
            return;
        }

        setIsEditing(false);
        setCurrentValue(event.target.value);
        props.onChange(event.target.value);
    };

    const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const createEditComponent = (): JSX.Element => {
        switch (props.type) {
            case 'text':
                return (
                    <Input
                        {...props.inputProps}
                        type="text"
                        sx={{ width: '100%' }}
                        value={currentValue}
                        autoFocus
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        onKeyPress={handleTextKeyPress}
                        onChange={handleTextChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={handleSaveClicked} onMouseDown={preventDefault}>
                                    <Check />
                                </IconButton>
                                <IconButton onClick={handleCancelClicked} onMouseDown={preventDefault}>
                                    <Cancel />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                );
            case 'select':
                return (
                    <Select
                        sx={{ width: '100%' }}
                        native={false}
                        defaultOpen={true}
                        value={currentValue}
                        onChange={handleSelection}
                        onClose={() => setIsEditing(false)}
                    >
                        {(props.options || []).map((option) => (
                            <MenuItem key={`select-item=${option}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                );
        }
    };

    return (
        <>
            {isEditing && (
                <>
                    <form ref={formRef} noValidate>
                        {createEditComponent()}
                    </form>
                </>
            )}
            {!isEditing && (
                <>
                    <HoverLabel onClick={handleOnLabelClick}>{props.value}</HoverLabel>
                </>
            )}
        </>
    );
};

export default InlineEditField;
