import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const UpdateApplicationQuestionResponseSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const UpdateApplicationQuestionResponseResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    UpdateApplicationQuestionResponseSuccessResponseSchema
]);
export type UpdateApplicationQuestionResponseResponse = z.infer<typeof UpdateApplicationQuestionResponseResponseSchema>;
