import { DraftsOutlined, GradingOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { ApplicationStatus } from '@rqr/deal-flow-abstractions';
import * as React from 'react';

interface ApplicationStatusBadgeProps {
    status: ApplicationStatus;
}

const ApplicationStatusBadge: React.FC<ApplicationStatusBadgeProps> = (props: ApplicationStatusBadgeProps) => {
    const [label, badgeColor, badgeIcon] = (() => {
        switch (props.status) {
            // case ApplicationStatus.Approved:
            //     return [ 'Approved', 'green', CheckOutlined];
            case ApplicationStatus.Draft:
                return ['Draft', 'goldenrod', DraftsOutlined];
            case ApplicationStatus.InReview:
                return ['In Review', 'blue', GradingOutlined];
            // case ApplicationStatus.Rejected:
            //     return [ 'Rejected', 'red', GppBadOutlined ];
        }
    })();

    return (
        <Box sx={{ height: '80px', width: '80px', border: `${badgeColor} 2px solid`, display: 'flex', flexShrink: 0 }}>
            <Grid sx={{ width: '100%' }}>
                <Grid item xs={12} sx={{ width: '100%', alignItems: 'center', display: 'flex', marginY: '8px' }}>
                    {React.createElement(badgeIcon, { fontSize: 'large', sx: { marginX: 'auto' } })}
                </Grid>
                <Grid item xs={12} sx={{ width: '100%' }}>
                    <Typography align="center" fontSize={'10pt'}>
                        {label}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ApplicationStatusBadge;
