import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { RegistrationCodeSchema } from './registrationCode';

export const GetRegistrationCodeSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        registration_code: RegistrationCodeSchema
    })
);

export const GetRegistrationCodeResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    GetRegistrationCodeSuccessResponseSchema
]);
export type GetRegistrationCodeResponse = z.infer<typeof GetRegistrationCodeResponseSchema>;
