import styled from '@emotion/styled';
import { Block, Close, DeleteForever, LockReset } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Slide,
    Typography
} from '@mui/material';
import { Apps, AppsByRole, UserPublicData, UserRole } from '@rqr/deal-flow-abstractions';
import { DateTime } from 'luxon';
import * as React from 'react';
import InlineEditField from '../../components/InlineEditField';
import { ApiContext } from '../../providers/ApiProvider';
import areObjectsSame from '../../util/areObjectsSame';
import makeUserInitials from '../../util/makeUserInitials';

const HUNDRED_LESS_HUNDRED = 'calc(100% - 100px)';

const StyledPaper = styled(Paper)(() => ({
    position: 'absolute' as const,
    height: 'calc(100% - 75px)',
    top: '72px',
    right: '-5px',
    width: '500px',
    padding: '8px',
    paddingRight: '20px',
    zIndex: 50
}));

export interface EditUserSlideOutPanelProps {
    id: string;
    onClose: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const EditUserSlideOutPanel: React.FC<EditUserSlideOutPanelProps> = (props: EditUserSlideOutPanelProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const [originalUser, setOriginalUser] = React.useState<UserPublicData>();
    const [isDataChanged, setIsDataChanged] = React.useState(false);
    const [user, setUser] = React.useState<UserPublicData>();
    const api = React.useContext(ApiContext);

    React.useEffect(() => {
        setLoaded(true);
    }, []);

    React.useEffect(() => {
        setUser(undefined);

        (async () => {
            //todo: error handling
            const user = await api.user.getUserById(props.id);

            setUser(user);
            setOriginalUser({ ...user });
        })();
    }, [props.id]);

    React.useEffect(() => {
        setIsDataChanged(!areObjectsSame(originalUser, user));
    }, [user]);

    const handleUserDetailChange = (property: keyof UserPublicData) => (value: string) => {
        if (!user) {
            return;
        }

        if (property === 'role') {
            user.apps = user.apps.filter((app) => AppsByRole[value as UserRole].includes(app));
        }

        Reflect.set(user, property, value);

        setUser({ ...user });
    };

    const handleAppToggle = (app: Apps) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!user) {
            return;
        }

        if (event.target.checked) {
            //add app to user
            user.apps = [...new Set(user.apps.concat(app))].sort();
        } else {
            //remove app from user
            user.apps = user.apps.filter((userApp) => userApp !== app).sort();
        }

        setUser({ ...user });
    };

    return (
        <Slide direction="left" in={loaded} mountOnEnter unmountOnExit>
            <StyledPaper elevation={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Grid container sx={{ paddingLeft: '12px', paddingRight: '12px' }}>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={() => props.onClose()}>
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Box sx={{ marginLeft: '12px', marginRight: '6px' }}>
                                {user && (
                                    <Avatar sx={{ width: 64, height: 64, marginRight: '8px' }}>
                                        {makeUserInitials(user.first_name, user.last_name)}
                                    </Avatar>
                                )}
                                {!user && (
                                    <Skeleton
                                        variant="circular"
                                        animation="wave"
                                        sx={{ width: 64, height: 64, marginRight: '8px' }}
                                    />
                                )}
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                {user && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" fontWeight="bold">
                                                {user.first_name} {user.last_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', width: '100%' }}>
                                                <Box>
                                                    <Button startIcon={<LockReset />}>
                                                        <Typography component="span" sx={{ textTransform: 'initial' }}>
                                                            reset password
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                                <Box>
                                                    <Button startIcon={<Block />}>
                                                        <Typography component="span" sx={{ textTransform: 'initial' }}>
                                                            disable
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                                <Box>
                                                    <Button startIcon={<DeleteForever />}>
                                                        <Typography component="span" sx={{ textTransform: 'initial' }}>
                                                            delete
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                )}
                                {!user && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                sx={{ width: '100%', height: '32px', marginTop: '4px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                sx={{ width: '100%', marginTop: '4px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={12}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <Typography fontWeight="bold">General</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100px' }}>First Name:</td>
                                        <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                            {user && (
                                                <InlineEditField
                                                    value={user.first_name}
                                                    type="text"
                                                    onChange={handleUserDetailChange('first_name')}
                                                    inputProps={{
                                                        required: true,
                                                        inputProps: { minLength: 1, maxLength: 256 }
                                                    }}
                                                />
                                            )}
                                            {!user && (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100px' }}>Last Name:</td>
                                        <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                            {user && (
                                                <InlineEditField
                                                    value={user.last_name}
                                                    type="text"
                                                    onChange={handleUserDetailChange('last_name')}
                                                />
                                            )}
                                            {!user && (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <Typography fontWeight="bold">Role</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100px' }}>Role:</td>
                                        <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                            {user && (
                                                <InlineEditField
                                                    value={user.role}
                                                    type="select"
                                                    onChange={handleUserDetailChange('role')}
                                                    options={Object.keys(UserRole)}
                                                />
                                            )}
                                            {!user && (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <Typography fontWeight="bold">Apps</Typography>
                                        </td>
                                    </tr>
                                    {user &&
                                        Apps.map((app) => {
                                            const doesUserHaveApp = user.apps.includes(app);
                                            const isAppAllowedByRole = AppsByRole[user.role].includes(app);

                                            return (
                                                <tr key={`app-row-${app}`}>
                                                    <td colSpan={2}>
                                                        <Box sx={{ marginLeft: '24px' }}>
                                                            <FormControl>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                id={`app-checkbox-${app}`}
                                                                                onChange={handleAppToggle(app)}
                                                                                value={
                                                                                    doesUserHaveApp &&
                                                                                    isAppAllowedByRole
                                                                                }
                                                                                checked={
                                                                                    doesUserHaveApp &&
                                                                                    isAppAllowedByRole
                                                                                }
                                                                                disabled={!isAppAllowedByRole}
                                                                            />
                                                                        }
                                                                        label={app}
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Box>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    {!user &&
                                        new Array(3).fill('').map((_, index) => (
                                            <tr key={`app-skeleton-${index}`}>
                                                <td colSpan={2}>
                                                    <Skeleton
                                                        variant="rectangular"
                                                        animation="wave"
                                                        sx={{ width: '100%' }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <Typography fontWeight="bold">Contact</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100px' }}>Email:</td>
                                        <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                            {user && (
                                                <InlineEditField
                                                    value={user.email}
                                                    type="text"
                                                    onChange={handleUserDetailChange('email')}
                                                    inputProps={{
                                                        required: true,
                                                        inputProps: { minLength: 1, maxLength: 256 }
                                                    }}
                                                />
                                            )}
                                            {!user && (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <Typography fontWeight="bold">Login</Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '100px' }}>Last log-in:</td>
                                        <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                            {user &&
                                                DateTime.fromMillis(user.utc_last_login).toLocaleString(
                                                    DateTime.DATETIME_SHORT
                                                )}
                                            {!user && (
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    sx={{ width: '100%' }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            paddingLeft: '12px',
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: 'column'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '12px' }}>
                            <Button disabled={!isDataChanged}>Save</Button>
                        </Box>
                    </Box>
                </Box>
            </StyledPaper>
        </Slide>
    );
};

export default EditUserSlideOutPanel;
