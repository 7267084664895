import { z } from 'zod';

export const PasswordGrantSchema = z.object({
    grant_type: z.literal('password'),
    client_id: z.string(),
    username: z.string(),
    password: z.string(),
    scope: z.string()
});

export const AuthorizationCodeGrantSchema = z.object({
    grant_type: z.literal('refresh_token'),
    client_id: z.string(),
    refresh_token: z.string()
});

export const CreateTokenInputSchema = z.discriminatedUnion('grant_type', [
    PasswordGrantSchema,
    AuthorizationCodeGrantSchema
]);

export type CreateTokenInput = z.infer<typeof CreateTokenInputSchema>;
