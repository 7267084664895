import { z } from 'zod';
import { Apps } from './apps';
import { UserRole } from './userRole';
import { UserStatus } from './userStatus';

export const UserPublicDataSchema = z.object({
    id: z.string(),
    role: z.nativeEnum(UserRole),
    status: z.nativeEnum(UserStatus),
    email: z.string(),
    utc_created: z.number(),
    utc_last_login: z.number(),
    first_name: z.string(),
    last_name: z.string(),
    apps: z.array(z.enum(Apps))
});
export type UserPublicData = z.infer<typeof UserPublicDataSchema>;
