import { z } from 'zod';
import { ApplicationQuestion, ApplicationQuestionSchema } from '../models/applicationQuestion';
import { LegalResponseSchema } from '../models/legalResponse';

const ApplicationQuestionResponseSchema = z
    .object({
        question: ApplicationQuestionSchema,
        value: z.string()
    })
    .refine((data) => {
        //WARNING: do not add default case. All types must be accounted for here.
        switch (data.question.type) {
            case 'legal':
                try {
                    return LegalResponseSchema.safeParse(JSON.parse(data.value)).success;
                } catch (e) {
                    return false;
                }
            case 'checkbox':
                return data.value === 'true' || data.value === 'false';
            case 'dropdown':
                return data.question.options.indexOf(data.value) > -1;
            case 'longText':
                return data.value.length >= data.question.minLength && data.value.length <= data.question.maxLength;
            case 'money':
            case 'number':
                try {
                    const value = parseFloat(data.value);
                    return !isNaN(value) && value >= data.question.minValue && value <= data.question.maxValue;
                } catch (e) {
                    return false;
                }
            case 'multipleChoice':
                return data.question.options.indexOf(data.value) > -1;
            case 'multipleSelect': {
                const options = data.question.options;
                const items = JSON.parse(data.value) as string[];
                return !items.some((item) => options.indexOf(item) === -1);
            }
            case 'section':
                return false; //sections don't have answers...
            case 'shortText':
                return data.value.length >= data.question.minLength && data.value.length <= data.question.maxLength;
        }
    });

export function ValidateApplicationQuestionResponse(question: ApplicationQuestion, value: string) {
    return ApplicationQuestionResponseSchema.safeParse({
        question,
        value
    });
}
