import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const UpdateUserSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const UpdateUserResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    UpdateUserSuccessResponseSchema
]);
export type UpdateUserResponse = z.infer<typeof UpdateUserResponseSchema>;
