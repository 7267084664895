import { Edit, FileOpenOutlined } from '@mui/icons-material';
import { Box, Card, Typography, styled, Button } from '@mui/material';
import { DateTime } from 'luxon';
import * as React from 'react';
import ApplicationStatusBadge from './applicationStatusBadge';
import { ApplicationDataSummary, ApplicationStatus } from '@rqr/deal-flow-abstractions';

const StyledTitleTd = styled('td')(() => ({
    padding: 0,
    width: '64px'
}));

const StyledContentTd = styled('td')(() => ({
    padding: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const StyledTable = styled('table')(() => ({
    tableLayout: 'fixed',
    width: '100%'
}));

function formatTimestampMillis(timestamp: number): string {
    if (timestamp < 0) {
        return 'never';
    }

    return DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_SHORT);
}

interface ApplicationCardProps {
    data: ApplicationDataSummary;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const ApplicationCard: React.FC<ApplicationCardProps> = (props: ApplicationCardProps) => {
    const application = props.data;

    const [buttonLabel, buttonIcon] = (() => {
        switch (application.status) {
            case ApplicationStatus.InReview:
                return ['view', FileOpenOutlined];
            case ApplicationStatus.Draft:
                return ['edit', Edit];
        }
    })();

    return (
        <Card sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} elevation={3}>
            <ApplicationStatusBadge status={application.status} />
            <Box sx={{ flexGrow: 1, marginLeft: '8px', marginTop: '6px' }}>
                <StyledTable cellSpacing={0}>
                    <tbody>
                        <tr>
                            <StyledTitleTd>
                                <Typography component={'span'} fontWeight={'bold'}>
                                    Title:
                                </Typography>
                            </StyledTitleTd>
                            <StyledContentTd>
                                <Typography component={'span'}>{application.title}</Typography>
                            </StyledContentTd>
                        </tr>
                        <tr>
                            <StyledTitleTd>
                                <Typography component={'span'} fontSize={'9pt'}>
                                    Created:
                                </Typography>
                            </StyledTitleTd>
                            <StyledContentTd>
                                <Typography component={'span'} fontSize={'9pt'}>
                                    {formatTimestampMillis(application.utc_created)}
                                </Typography>
                            </StyledContentTd>
                        </tr>
                        <tr>
                            <StyledTitleTd>
                                <Typography component={'span'} fontSize={'9pt'}>
                                    Modified:
                                </Typography>
                            </StyledTitleTd>
                            <StyledContentTd>
                                <Typography component={'span'} fontSize={'9pt'}>
                                    {formatTimestampMillis(application.utc_modified)}
                                </Typography>
                            </StyledContentTd>
                        </tr>
                    </tbody>
                </StyledTable>
            </Box>
            <Box
                sx={{
                    width: '76px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flexShrink: 0,
                    paddingRight: '8px'
                }}
            >
                <Button variant="text" startIcon={React.createElement(buttonIcon)} onClick={props.onClick}>
                    {buttonLabel}
                </Button>
            </Box>
        </Card>
    );
};

export default ApplicationCard;
