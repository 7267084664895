import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ApplicationsList from './applicationsList';
import ViewApplication from './viewApplication';

const Submissions = () => {
    return (
        <Routes>
            <Route path="/*" element={<ApplicationsList />} />
            <Route path="view/:applicationId" element={<ViewApplication />} />
        </Routes>
    );
};

export default Submissions;
