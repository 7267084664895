import { DataGridPro, GridColDef, GridRowSelectionModel, GridToolbar } from '@mui/x-data-grid-pro';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Breadcrumbs, Button, Grid } from '@mui/material';
import { ApiContext } from '../../providers/ApiProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import EditUserSlideOutPanel from './editUserSlideOutPanel';
import { UserPublicData } from '@rqr/deal-flow-abstractions';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function stringifyDateTime(value: any): string {
    if (value === -1) {
        return 'never';
    }

    return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_SHORT);
}

const CustomGridToolbar = () => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <GridToolbar />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button>Create User</Button>
            </Grid>
        </Grid>
    );
};

const dataGridColumns: GridColDef[] = [
    {
        field: 'email',
        headerName: 'Email',
        width: 250
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 150
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
        width: 150
    },
    {
        field: 'first_name',
        headerName: 'First Name',
        width: 150
    },
    {
        field: 'utc_last_login',
        headerName: 'Last Login',
        valueFormatter: (value, row) => stringifyDateTime(row.utc_created),
        width: 200
    },
    {
        field: 'utc_created',
        headerName: 'Created',
        valueFormatter: (value, row) => stringifyDateTime(row.utc_created),
        width: 200
    }
];

const UserList = () => {
    const { id } = useParams();
    const [selectedUserId, setSelectedUserId] = React.useState<string>();
    const [users, setUsers] = React.useState<UserPublicData[]>();
    const api = React.useContext(ApiContext);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            setUsers(await api.user.listUsers());
            setSelectedUserId(id);
        })();
    }, []);

    React.useEffect(() => {
        //typing says userId is basically a number, but when you pass in
        //string data, it comes back a string...
        const userId = selectionModel[0]?.toString();

        setSelectedUserId(userId);

        if (!userId) {
            navigate('.');
        } else {
            navigate(`/users/${userId}`);
        }
    }, [selectionModel]);

    const handleSlideOutClose = () => {
        navigate('./..');
        setSelectedUserId(undefined);
    };

    return (
        <div style={{ overflow: 'hidden', height: '100%' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Users</Typography>
            </Breadcrumbs>
            <br />
            <DataGridPro
                columns={dataGridColumns}
                rows={users || []}
                loading={!users}
                rowHeight={38}
                slots={{
                    toolbar: CustomGridToolbar
                }}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                rowSelectionModel={selectionModel}
            />
            {selectedUserId && <EditUserSlideOutPanel id={selectedUserId} onClose={handleSlideOutClose} />}
        </div>
    );
};

export default UserList;
