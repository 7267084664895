import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { ApplicationSchema } from './application';

export const GetApplicationSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        application: ApplicationSchema
    })
);

export const GetApplicationResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    GetApplicationSuccessResponseSchema
]);
export type GetApplicationResponse = z.infer<typeof GetApplicationResponseSchema>;
