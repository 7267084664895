import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material';
import { ApiErrorResponseSchema, Application, DropdownApplicationQuestion } from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import * as React from 'react';
import { ApiContext } from '../../../providers/ApiProvider';
import unused from '../../../util/unused';
import StyledPre from '../../StyledPre';
import Subtext from './subtext';

export type DropdownResponseProps = DropdownApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const DropdownResponse: React.FC<DropdownResponseProps> = (props: DropdownResponseProps) => {
    const { prompt, id, responses, options, editable, applicationId, subtext, validate, onResponseChanged } = props;
    const [response, setResponse] = React.useState(responses[id] || '');
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);

    unused(saving);

    React.useEffect(() => {
        if (validate && !options.some((option) => option === response)) {
            setError('please select a valid option');
        }
    }, [validate, response]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        if (!editable) {
            return;
        }

        const {
            target: { value }
        } = event;

        const newValue = typeof value === 'string' ? value : value[0];

        saveToApi(newValue);
        setResponse(newValue);

        if (onResponseChanged) {
            onResponseChanged(newValue);
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(response.data.message);
                    } else {
                        setError('an unknown error occurred');
                    }
                } else {
                    setError('an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    return (
        <>
            <p>
                {prompt}
                {subtext && (
                    <>
                        <br />
                        <Subtext>{subtext}</Subtext>
                    </>
                )}
            </p>
            {editable && (
                <>
                    <FormControl fullWidth error={Boolean(error)}>
                        <InputLabel id={`select-label-${id}`}>Response</InputLabel>
                        <Select
                            labelId={`select-label-${id}`}
                            id={`select-${id}`}
                            value={[response]}
                            onChange={handleChange}
                            input={<OutlinedInput label="Response" />}
                            fullWidth
                            MenuProps={MenuProps}
                        >
                            {options.map((option) => (
                                <MenuItem key={`select-menu-item-${id}-${option}`} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </>
            )}
            {!editable && (
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flexShrink: 0, flexGrow: 0 }}>
                        <Typography variant="body1" component="span" sx={{ marginLeft: '24px', fontWeight: 'bold' }}>
                            Response:&nbsp;&nbsp;
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <StyledPre>{response || 'No response provided.'}</StyledPre>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default DropdownResponse;
