import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const CreateConfirmationSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const CreateConfirmationResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    CreateConfirmationSuccessResponseSchema
]);
export type CreateConfirmationResponse = z.infer<typeof CreateConfirmationResponseSchema>;
