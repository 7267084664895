import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material';
import { ApiErrorResponseSchema, Application, MultipleChoiceApplicationQuestion } from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import * as React from 'react';
import { ApiContext } from '../../../providers/ApiProvider';
import unused from '../../../util/unused';
import Subtext from './subtext';

export type MultipleChoiceResponseProps = MultipleChoiceApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const MultipleChoiceResponse: React.FC<MultipleChoiceResponseProps> = (props: MultipleChoiceResponseProps) => {
    const { prompt, id, responses, options, editable, applicationId, subtext, validate, onResponseChanged } = props;
    const [response, setResponse] = React.useState(responses[id] || '');
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);

    unused(saving);

    React.useEffect(() => {
        if (validate && !options.some((option) => option === response)) {
            setError('please select a valid option');
        }
    }, [validate, response]);

    const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (!editable) {
            return;
        }

        saveToApi(value);

        setResponse(value);

        if (onResponseChanged) {
            onResponseChanged(value);
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(`save failed: response.data.message`);
                    } else {
                        setError('save failed: an unknown error occurred');
                    }
                } else {
                    setError('save failed: an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    return (
        <>
            <p>
                {prompt}
                {subtext && (
                    <>
                        <br />
                        <Subtext>{subtext}</Subtext>
                    </>
                )}
            </p>
            {editable && (
                <>
                    <FormControl fullWidth error={Boolean(error)}>
                        <RadioGroup
                            id={`radiogroup-${id}`}
                            row
                            name={`multiple-choice-${id}`}
                            onChange={handleRadioGroupChange}
                            value={response}
                        >
                            {options.map((option) => (
                                <FormControlLabel
                                    key={`multiple-choice-${id}-${option}`}
                                    value={option}
                                    control={<Radio />}
                                    label={option}
                                />
                            ))}
                        </RadioGroup>
                        {error && <FormHelperText>{error}</FormHelperText>}
                    </FormControl>
                </>
            )}
            {!editable && (
                <>
                    <Typography variant="body1" component="span" sx={{ marginLeft: '24px', fontWeight: 'bold' }}>
                        Response:{' '}
                    </Typography>
                    <Typography variant="body1" component="span">
                        {response}
                    </Typography>
                </>
            )}
        </>
    );
};

export default MultipleChoiceResponse;
