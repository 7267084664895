import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { UserPublicDataSchema } from './userPublicData';

export const ListUsersDataSchema = z.array(UserPublicDataSchema);

export type ListUsersData = z.infer<typeof ListUsersDataSchema>;

export const ListUsersSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        users: ListUsersDataSchema
    })
);

export const ListUsersResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    ListUsersSuccessResponseSchema
]);
export type ListUsersResponse = z.infer<typeof ListUsersResponseSchema>;
