import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';
import { UserPublicDataSchema } from './userPublicData';

export const GetMeSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(
    z.object({
        me: UserPublicDataSchema
    })
);

export const GetMeResponseSchema = z.discriminatedUnion('status', [ApiErrorResponseSchema, GetMeSuccessResponseSchema]);
export type GetMeResponse = z.infer<typeof GetMeResponseSchema>;
