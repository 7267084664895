import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Skeleton
} from '@mui/material';
import { ApiContext } from '../../providers/ApiProvider';
import { UserPublicData } from '@rqr/deal-flow-abstractions';
import InlineEditField from '../../components/InlineEditField';
import { DateTime } from 'luxon';
import areObjectsSame from '../../util/areObjectsSame';
import { LockReset } from '@mui/icons-material';
import * as uuid from 'uuid';
import getChangedProperties from '../../util/getChangedProperties';

const HUNDRED_LESS_HUNDRED = 'calc(100% - 100px)';

const Account = () => {
    const api = React.useContext(ApiContext);
    const [me, setMe] = React.useState<UserPublicData>();
    const [originalMe, setOriginalMe] = React.useState<UserPublicData>();
    const [isDataChanged, setIsDataChanged] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            try {
                const myData = await api.user.getMe();

                setMe(myData);
                setOriginalMe({ ...myData });
            } catch (e) {
                //oh!
            }
        })();
    }, []);

    React.useEffect(() => {
        setIsDataChanged(!areObjectsSame(originalMe, me));
    }, [me]);

    const handleUserDetailChange = (property: keyof UserPublicData) => (value: string) => {
        if (!me) {
            return;
        }

        Reflect.set(me, property, value);

        setMe({ ...me });
    };

    const handlePasswordResetClicked = async (): Promise<void> => {
        setOpen(true);

        await api.user.createResetRequest(uuid.v4(), me?.email || '');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        setIsSubmitting(true);

        (async () => {
            try {
                if (!me) {
                    throw new Error('impossible condition');
                }

                await api.user.updateUser(
                    originalMe?.id || '',
                    Array.from(getChangedProperties(me, originalMe)).reduce((memo, value) => {
                        return {
                            [value[0]]: value[1],
                            ...memo
                        };
                    }, {})
                );

                setOriginalMe({ ...me });
                setIsDataChanged(false);
            } catch (e) {
                //
            }

            setIsSubmitting(false);
        })();
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">Your Account</Typography>
            </Breadcrumbs>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Password reset'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        A password reset confirmation has been sent to your email address <strong>{me?.email}</strong>.
                        Please check your email to complete the password reset.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Grid container sx={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '12px' }}>
                    <Grid item xs={12}>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <Typography fontWeight="bold">General</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '100px' }}>First Name:</td>
                                    <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                        {me && (
                                            <InlineEditField
                                                value={me.first_name}
                                                type="text"
                                                onChange={handleUserDetailChange('first_name')}
                                                inputProps={{
                                                    required: true,
                                                    inputProps: { minLength: 1, maxLength: 256 }
                                                }}
                                            />
                                        )}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '100px' }}>Last Name:</td>
                                    <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                        {me && (
                                            <InlineEditField
                                                value={me.last_name}
                                                type="text"
                                                onChange={handleUserDetailChange('last_name')}
                                            />
                                        )}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Typography fontWeight="bold">Role</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '100px' }}>Role:</td>
                                    <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                        {me && me.role}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Typography fontWeight="bold">Contact</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '100px' }}>Email:</td>
                                    <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                        {me && <span>{me.email}</span>}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Typography fontWeight="bold">Login</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '100px' }}>Last log-in:</td>
                                    <td style={{ width: HUNDRED_LESS_HUNDRED }}>
                                        {me &&
                                            DateTime.fromMillis(me.utc_last_login).toLocaleString(
                                                DateTime.DATETIME_SHORT
                                            )}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <Typography fontWeight="bold">Security</Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {me && (
                                            <Box>
                                                <Button startIcon={<LockReset />}>
                                                    <Typography
                                                        component="span"
                                                        onClick={handlePasswordResetClicked}
                                                        sx={{ textTransform: 'initial' }}
                                                    >
                                                        reset password
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        )}
                                        {!me && (
                                            <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} />
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        paddingTop: '12px',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Button disabled={!isDataChanged || isSubmitting} onClick={handleSave}>
                            Save
                        </Button>
                        {isSubmitting && <CircularProgress size="20px" />}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Account;
