import { UserRole } from './userRole';

export const Apps = ['Applications', 'Submissions', 'Users'] as const;
export type Apps = (typeof Apps)[number];

export const ApplicantApps = ['Applications'] as const;
export type ApplicantApps = (typeof ApplicantApps)[number];

export const AdvisorApps = ['Submissions', 'Users'] as const;
export type AdvisorApps = (typeof AdvisorApps)[number];

export const AppsByRole: { [x in UserRole]: Readonly<Apps[]> } = {
    Applicant: ApplicantApps,
    Advisor: AdvisorApps
};
