import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { ApiErrorResponseSchema, Application, CheckboxApplicationQuestion } from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import * as React from 'react';
import { ApiContext } from '../../../providers/ApiProvider';
import unused from '../../../util/unused';

export type CheckboxResponseProps = CheckboxApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const CheckboxResponse: React.FC<CheckboxResponseProps> = (props: CheckboxResponseProps) => {
    const { prompt, id, responses, label, editable, applicationId, onResponseChanged } = props;
    const [response, setResponse] = React.useState(responses[id] || 'false');
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);

    unused(error, saving);

    const handleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!editable) {
            return;
        }

        const nextValue = !(event.target.value === 'true');
        const nextValueString = JSON.stringify(nextValue);

        if (nextValueString !== response) {
            saveToApi(nextValueString);

            setResponse(nextValueString);

            if (onResponseChanged) {
                onResponseChanged(nextValueString);
            }
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(response.data.message);
                    } else {
                        setError('an unknown error occurred');
                    }
                } else {
                    setError('an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    return (
        <>
            <p>{prompt}</p>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={`checkbox-${id}`}
                            onChange={handleCheckboxChanged}
                            value={response}
                            checked={response === 'true'}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </>
    );
};

export default CheckboxResponse;
