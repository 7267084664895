import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ApiContext } from '../../providers/ApiProvider';
import styled from '@emotion/styled';
import { LockOutlined, ReportProblem } from '@mui/icons-material';
import Copyright from '../../components/Copyright';
import { AxiosError } from 'axios';
import { ApiErrorResponseSchema } from '@rqr/deal-flow-abstractions';
import { ApiResponseErrorCodes } from '@rqr/deal-flow-abstractions';

const StyledReportProblem = styled(ReportProblem)(() => ({
    color: 'red'
}));

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatApiError(e: any): string {
    if (e instanceof AxiosError) {
        const error = ApiErrorResponseSchema.safeParse(e.response?.data);

        if (!error.success) {
            return 'the server returned an un-expected result';
        } else if (error.data.code === ApiResponseErrorCodes.ResourceExpired) {
            return 'the confirmation link has expired';
        } else {
            return error.data.message;
        }
    }

    return e.message || 'an unknown error has occured';
}

const RegisterConfirm = () => {
    const { confirmationCode } = useParams();
    const api = React.useContext(ApiContext);
    const [error, setError] = React.useState<Error>();
    const [email, setEmail] = React.useState(localStorage.getItem('registration_email') || '');
    const [isLoading, setIsLoading] = React.useState(true);
    const [isComplete, setIsComplete] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        (async (code?: string) => {
            if (!code) {
                //should be impossible
                setError(new Error('no confirmation code was provided'));
            } else if (email.length > 0) {
                handleSubmit();
            }

            setIsLoading(false);
        })(confirmationCode);
    }, []);

    const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        if (!confirmationCode) {
            //should be impossible
            setError(new Error('no confirmation code was provided'));

            return;
        }

        if (event && !event.currentTarget.reportValidity()) {
            return;
        }

        setIsSubmitting(true);

        try {
            await api.registration.confirmRegistration(email, confirmationCode);

            setIsComplete(true);
        } catch (e) {
            console.log(e);
            if (e instanceof Error) {
                setError(e);
            } else {
                setError(new Error('an unknown error has occurred'));
            }

            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Stack alignItems="center" m={33}>
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Confirm Registration
                    </Typography>
                    {}
                    {isComplete && !error && (
                        <>
                            <br />
                            <Typography>Your registration has been confirmed</Typography>
                            <br />
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {!isComplete && !error && (
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        type="email"
                                        value={email}
                                        disabled={isSubmitting}
                                        onChange={(event) => setEmail(event.target.value)}
                                        autoComplete="email"
                                    />
                                </Grid>
                            </Grid>
                            {isSubmitting && (
                                <Stack alignItems="center" sx={{ marginTop: '24px' }}>
                                    <CircularProgress />
                                </Stack>
                            )}
                            {!isSubmitting && (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isSubmitting}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Confirm Registration
                                </Button>
                            )}
                        </Box>
                    )}
                    {error && (
                        <>
                            <Box sx={{ display: 'flex', marginTop: '18px' }}>
                                <StyledReportProblem fontSize="large" />
                                <Box sx={{ marginLeft: '12px' }}>
                                    <Typography>An error occurred confirming your account:</Typography>
                                    <Typography>{formatApiError(error)}</Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
                <br />
                <Copyright />
            </Container>
        </>
    );
};

export default RegisterConfirm;
