import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const CheckboxApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('checkbox'),
        prompt: z.string(),
        label: z.string(),
        checkRequired: z.boolean()
    })
);
export type CheckboxApplicationQuestion = z.infer<typeof CheckboxApplicationQuestionSchema>;
