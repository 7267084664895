import { UpdateApplicationInput, UpdateApplicationResponse } from '@rqr/deal-flow-abstractions';
import {
    AuthTokenData,
    UserPublicData,
    ListApplicationsResponse,
    ListApplicationsData,
    GetApplicationResponse,
    CreateApplicationInput,
    UpdateApplicationQuestionInput,
    UpdateApplicationQuestionResponseResponse
} from '@rqr/deal-flow-abstractions';
import axios from 'axios';

export default class ApplicationsController {
    private readonly token?: AuthTokenData;
    private readonly user?: UserPublicData;

    public constructor(token?: AuthTokenData, user?: UserPublicData) {
        this.token = token;
        this.user = user;
    }

    public async updateApplication(
        applicationId: string,
        change: UpdateApplicationInput
    ): Promise<UpdateApplicationResponse> {
        if (!this.token || !this.user) {
            throw new Error(`POST /applications requires authentication`);
        }

        const result = await axios.patch<UpdateApplicationQuestionResponseResponse>(
            `${process.env.REACT_APP_API_BASE}/api/applications/${encodeURIComponent(applicationId)}`,
            change,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        return result.data;
    }

    public async updateApplicationQuestion(
        applicationId: string,
        questionId: string,
        value: string
    ): Promise<UpdateApplicationQuestionResponseResponse> {
        if (!this.token || !this.user) {
            throw new Error(`POST /applications requires authentication`);
        }

        //ensure changes to API definition cause a compilation failure
        const payload: UpdateApplicationQuestionInput = {
            value
        };

        const result = await axios.put<UpdateApplicationQuestionResponseResponse>(
            `${process.env.REACT_APP_API_BASE}/api/applications/${encodeURIComponent(
                applicationId
            )}/question/${encodeURIComponent(questionId)}`,
            payload,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        return result.data;
    }

    public async createApplication(title: string): Promise<GetApplicationResponse> {
        if (!this.token || !this.user) {
            throw new Error(`POST /applications requires authentication`);
        }

        //ensure changes to API definition cause a compilation failure
        const payload: CreateApplicationInput = {
            title
        };

        const result = await axios.post<GetApplicationResponse>(
            `${process.env.REACT_APP_API_BASE}/api/applications`,
            payload,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        return result.data;
    }

    public async getApplication(applicationId: string): Promise<GetApplicationResponse> {
        if (!this.token || !this.user) {
            throw new Error(`GET /applications/{applicationId} requires authentication`);
        }

        const result = await axios.get<GetApplicationResponse>(
            `${process.env.REACT_APP_API_BASE}/api/applications/${encodeURIComponent(applicationId)}`,
            {
                headers: {
                    authorization: `Bearer ${this.token.access_token}`
                }
            }
        );

        if (result.data.status === 'OK') {
            return result.data;
        }

        throw new Error('impossible condition');
    }

    public async listApplications(): Promise<ListApplicationsData> {
        if (!this.token || !this.user) {
            throw new Error(`GET /applications requires authentication`);
        }

        const result = await axios.get<ListApplicationsResponse>(`${process.env.REACT_APP_API_BASE}/api/applications`, {
            headers: {
                authorization: `Bearer ${this.token.access_token}`
            }
        });

        if (result.data.status === 'OK') {
            return result.data.applications;
        }

        throw new Error('impossible condition');
    }
}
