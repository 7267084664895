import { z } from 'zod';
import { ApplicationQuestionBaseSchema } from './applicationQuestionBase';

export const SectionApplicationQuestionSchema = ApplicationQuestionBaseSchema.merge(
    z.object({
        type: z.literal('section'),
        name: z.string()
    })
);
export type SectionApplicationQuestion = z.infer<typeof SectionApplicationQuestionSchema>;
