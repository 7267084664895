import { z } from 'zod';

export const CreateRegistrationInputSchema = z.object({
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    password: z.string(),
    registration_code: z.string()
});

export type CreateRegistrationInput = z.infer<typeof CreateRegistrationInputSchema>;
