import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { AuthContext } from '../../providers/AuthProvider';

const Bootstrap = () => {
    const { ready } = React.useContext(AuthContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        ready.then((result) => {
            if (result?.token && result?.user) {
                if (window.location.pathname === '/') {
                    //there is no path, route to registration screen
                    navigate(result.user.apps[0].toLowerCase());
                }
            } else {
                if (window.location.pathname === '/') {
                    //there is no path, route to registration screen
                    navigate(`/register/${process.env.REACT_APP_DEFAULT_REGISTRATION_CODE}`);
                }
            }
        });
    }, []);

    return (
        <Stack alignItems="center" m={33}>
            <CircularProgress />
        </Stack>
    );
};

export default Bootstrap;
