import { z } from 'zod';
import { ApiErrorResponseSchema, ApiSuccessResponseBaseSchema } from './apiResponseBase';

export const UpdateApplicationResponseSuccessResponseSchema = ApiSuccessResponseBaseSchema.merge(z.object({}));

export const UpdateApplicationResponseSchema = z.discriminatedUnion('status', [
    ApiErrorResponseSchema,
    UpdateApplicationResponseSuccessResponseSchema
]);
export type UpdateApplicationResponse = z.infer<typeof UpdateApplicationResponseSchema>;
