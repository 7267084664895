import { Download } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Link,
    TextField,
    Typography
} from '@mui/material';
import {
    ApiErrorResponseSchema,
    Application,
    LegalApplicationQuestion,
    LegalResponse as LegalResponseType
} from '@rqr/deal-flow-abstractions';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import * as React from 'react';
import { ApiContext } from '../../../providers/ApiProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import unused from '../../../util/unused';

export type LegalResponseProps = LegalApplicationQuestion &
    Pick<Application, 'responses'> & {
        editable: boolean;
        applicationId: string;
        validate?: boolean;
        onResponseChanged?: (newValue: string) => void;
    };

const LegalResponse: React.FC<LegalResponseProps> = (props: LegalResponseProps) => {
    const { prompt, id, responses, editable, applicationId, text, agreementUrl, validate, onResponseChanged } = props;
    const [response, setResponse] = React.useState<LegalResponseType | undefined>(
        responses[id] ? JSON.parse(responses[id]) : undefined
    );
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState<string>();
    const api = React.useContext(ApiContext);
    const auth = React.useContext(AuthContext);

    const legalAgreed = response?.accepted;

    unused(error, saving);

    const handleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!editable) {
            return;
        }

        const user = auth.user;

        if (!user) {
            throw new Error('user not logged in');
        }

        const accepted = !(event.target.value === 'true');
        const nextValue: LegalResponseType = {
            accepted,
            utcAccepted: Date.now(),
            acceptedBy: accepted ? `${user.last_name}, ${user.first_name}` : 'N/A',
            acceptedByUserId: accepted ? user.id : 'N/A'
        };
        const nextValueString = JSON.stringify(nextValue);

        if (nextValueString !== JSON.stringify(response)) {
            saveToApi(nextValueString);

            setResponse(nextValue);

            if (onResponseChanged) {
                onResponseChanged(nextValueString);
            }
        }
    };

    const saveToApi = React.useMemo(
        () => async (value: string) => {
            setSaving(true);
            setError(undefined);

            try {
                await api.applications.updateApplicationQuestion(applicationId, id, value);
            } catch (e) {
                if (e instanceof AxiosError) {
                    const response = ApiErrorResponseSchema.safeParse(e.response?.data);

                    if (response.success) {
                        setError(response.data.message);
                    } else {
                        setError('an unknown error occurred');
                    }
                } else {
                    setError('an unknown error occurred');
                }
            }

            setSaving(false);
        },
        []
    );

    return (
        <>
            {!editable && (
                <>
                    <>
                        <br />
                        <Typography variant="body1" component="span" sx={{ marginLeft: '24px', fontWeight: 'bold' }}>
                            Accepted:{' '}
                        </Typography>
                        <Typography variant="body1" component="span">
                            {legalAgreed ? 'yes' : 'no'}
                        </Typography>
                        {legalAgreed && (
                            <>
                                <br />
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={{ marginLeft: '24px', fontWeight: 'bold' }}
                                >
                                    Accepted At:{' '}
                                </Typography>
                                <Typography variant="body1" component="span">
                                    {DateTime.fromMillis(response.utcAccepted).toLocaleString(DateTime.DATETIME_SHORT)}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    component="span"
                                    sx={{ marginLeft: '24px', fontWeight: 'bold' }}
                                >
                                    Accepted By:{' '}
                                </Typography>
                                <Typography variant="body1" component="span">
                                    {`${response.acceptedBy} (id=${response.acceptedByUserId})`}
                                </Typography>
                            </>
                        )}
                        <br />
                        <Link href={agreementUrl} target="_blank">
                            <Box sx={{ display: 'flex', marginTop: '12px' }}>
                                <Box>
                                    <Download />
                                </Box>
                                <Box>Download Legal Agreement</Box>
                            </Box>
                        </Link>
                    </>
                </>
            )}
            {editable && (
                <>
                    <TextField
                        id="textfield-legal"
                        multiline
                        minRows={4}
                        maxRows={16}
                        value={text}
                        fullWidth
                        sx={{ marginTop: '12px' }}
                        onChange={() => {
                            /* empty block */
                        }}
                    />
                    <Link href={agreementUrl} target="_blank">
                        <Box sx={{ display: 'flex', marginTop: '12px' }}>
                            <Box>
                                <Download />
                            </Box>
                            <Box>Download Legal Agreement</Box>
                        </Box>
                    </Link>
                    <FormControl error={validate && !legalAgreed}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="checkbox-legal"
                                        onChange={handleCheckboxChanged}
                                        value={legalAgreed}
                                        checked={legalAgreed}
                                    />
                                }
                                label={prompt}
                            />
                        </FormGroup>
                        {validate && !legalAgreed && (
                            <FormHelperText>
                                you must agree to the legal terms to submit this application.
                            </FormHelperText>
                        )}
                    </FormControl>
                </>
            )}
        </>
    );
};

export default LegalResponse;
